import React, { useEffect, useRef, useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Avatar,
	Box,
	Checkbox,
	IconButton,
	List,
} from "@mui/material";

import { useAuth } from "../auth/auth-context";

import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";

import { BusinessNumbersConfigureViewModel } from "./business-numbers-configure.view-model";
import { BusinessNumbersConfigureProps } from "./business-numbers-configure.props";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import StatusChip from "src/components/atoms/status-chip/status-chip";
import BrandingConfiguration from "../branding-configuration/branding-configuration";
import { BusinessNumberModel } from "src/types/models/apiModels";

const BusinessNumbersConfigure: React.FC<BusinessNumbersConfigureProps> = ({
	scProvidedNumber,
	onSelect,
	business,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook
	const viewModel = useRef<BusinessNumbersConfigureViewModel | null>(null);
	const [businessNumbers, setBusinessNumbers] = useState<
		BusinessNumberModel[]
	>([]);
	const [loading, setLoading] = useState(true);
	const [isChecked, setIsChecked] = useState(false);
	const [selectedNumbers, setSelectedNumbers] = useState<
		BusinessNumberModel[]
	>([]);

	const handleCheckboxChange = (number: string, event: any) => {
		setIsChecked(event.target.checked);
		const isChecked = event.target.checked;
		const selectedItem = businessNumbers.find(
			(item) => item.businessNumber === number
		);
		if (isChecked && selectedItem) {
			// If checked, add the selected item to the selectedNumbers array
			setSelectedNumbers((prevSelected) => [
				...prevSelected,
				{ ...selectedItem },
			]);
		} else {
			// If unchecked, remove the item from the selectedNumbers array
			setSelectedNumbers((prevSelected) =>
				prevSelected.filter((item) => item.businessNumber !== number)
			);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new BusinessNumbersConfigureViewModel(
						user.accessToken
					);
					const response = await viewModel.current?.fetchList(
						business.businessId
					);
					console.log("fetchData", response);
					setBusinessNumbers(() =>
						response.filter(
							(number) =>
								number.status === "Active" ||
								number.status === "Approved"
						)
					);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};

		fetchData();
	}, [user]);

	useEffect(() => {
		if (onSelect) {
			onSelect(selectedNumbers);
		}
	}, [selectedNumbers]);
	if (loading) {
		return (
			<LoadingPlaceHolder message="Loading your business data, please hold on!" />
		); // Pass the optional message
	}

	function handleBusinessNumberChange(number: BusinessNumberModel): void {
		setSelectedNumbers((prevData) =>
			prevData.map((item) =>
				item.businessNumber === number.businessNumber ? number : item
			)
		);
		setBusinessNumbers((prevData) =>
			prevData.map((item) =>
				item.businessNumber === number.businessNumber ? number : item
			)
		);
	}

	return (
		<Box
			className="main-content"
			sx={{
				flexGrow: 1,
				padding: 3,
				marginLeft: `10px`,
				transition: "margin-left 0.3s ease",
			}}
		>
			<Box>
				<List sx={{ width: "100%", bgcolor: "transparent" }}>
					{businessNumbers.map((number, index) => (
						<Accordion key={index}>
							<AccordionSummary
								expandIcon={
									<IconButton disabled={!isChecked}>
										<ExpandMoreIcon />
									</IconButton>
								}
								aria-controls="panel1-content"
								id="panel1-header"
								onClick={(e) => e.stopPropagation()} // Prevent default click behavior on the entire summary
							>
								<Box
									display="flex"
									alignItems="center"
									width="100%"
								>
									{/* Checkbox at the beginning */}
									<Checkbox
										checked={isChecked}
										onChange={(event) =>
											handleCheckboxChange(
												number.businessNumber,
												event
											)
										}
									/>

									{/* Space between Checkbox and Avatar */}
									<Box ml={1}>
										<Avatar
											src={
												number.imageFile?.signedUrl
													? number.imageFile
															?.signedUrl
													: business.logo?.signedUrl
											}
										/>
									</Box>

									{/* Space between Avatar and Label */}
									<Box ml={2} flexGrow={1}>
										<SCLabel
											color="#000"
											fontWeight="normal"
											align="left"
											textTransform="none"
										>
											{number.businessNumber}
										</SCLabel>
									</Box>

									{/* Space between Label and Status Chip */}
									<Box ml={2}>
										<StatusChip status={number.status} />
									</Box>
								</Box>
							</AccordionSummary>
							<AccordionDetails>
								<BrandingConfiguration
									business={business}
									number={number.businessNumber}
									editNumber={undefined}
									scProvidedNumber={scProvidedNumber}
									onChange={handleBusinessNumberChange}
								></BrandingConfiguration>
							</AccordionDetails>
						</Accordion>
					))}
				</List>
				{businessNumbers.length === 0 && (
					<Alert severity="warning">
						No approved numbers available for branding
					</Alert>
				)}
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: 2,
						marginBottom: 2,
					}}
				></Box>
			</Box>
		</Box>
	);
};

export default BusinessNumbersConfigure;
