import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, ListItemAvatar, ListItemText } from "@mui/material";

import { BusinessListProps } from "./business-list.props";
import { BusinessListViewModel } from "./business-list.view-model";
import { useAuth } from "../auth/auth-context";

import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import EntityList from "../entity-list/entity-list";
import StatusChip from "src/components/atoms/status-chip/status-chip";
import { BusinessListModel } from "src/types/models/apiModels";

const BusinessList: React.FC<BusinessListProps> = ({
	onAddNewClick,
	selectMode,
	onSelect,
	onClose,
	withStatuses,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook
	const [data, setData] = useState<BusinessListModel[]>([]);
	const [loading, setLoading] = useState(true);
	const viewModel = useRef<BusinessListViewModel | null>(null);
	const [selectedBusiness, setSelectedBusiness] = useState<string | null>(
		null
	);

	const handleSelectBusiness = (business: BusinessListModel) => {
		setSelectedBusiness(business.businessId);
		if (onSelect) {
			onSelect(business);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new BusinessListViewModel(
						user.accessToken
					);
					const response = await viewModel.current?.fetchList();
					console.log("fetchData", response);

					if (withStatuses) {
						const filteredListOfBusiness = response.filter(
							(business) => withStatuses.includes(business.status)
						);

						setData(() => filteredListOfBusiness);
					} else {
						setData(() => response);
					}
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};

		fetchData();
	}, [user]);

	const renderBusinessItem = (business: BusinessListModel) => (
		<>
			<ListItemAvatar>
				<Avatar
					sx={{
						width: "50px",
						height: "50px",
						marginRight: 2,
					}}
					src={business.logo?.signedUrl}
				/>
			</ListItemAvatar>
			<ListItemText
				primary={
					<SCLabel
						color="#000"
						fontSize={16}
						fontWeight="medium"
						align="left"
						textTransform="none"
					>
						<span className="text-lg font-bold">
							{business.name}
						</span>
						<Box component="span" sx={{ mx: 1 }}>
							<span className="text-sm font-semibold">
								({business.businessCode})
							</span>
						</Box>
					</SCLabel>
				}
				secondary={<StatusChip status={business.status} />}
				primaryTypographyProps={{
					sx: {
						color:
							selectedBusiness === business.businessCode
								? "white"
								: "inherit",
						fontWeight:
							selectedBusiness === business.businessCode
								? "bold"
								: "normal",
					},
				}}
			/>
		</>
	);
	if (loading) {
		return (
			<LoadingPlaceHolder message="Loading your business data, please hold on!" />
		); // Pass the optional message
	}

	return (
		<div className="bg-white p-12 rounded-lg drop-shadow-2xl">
			<EntityList<BusinessListModel>
				data={data}
				selectMode={selectMode} // or false depending on your requirements
				onSelect={(selectedBusiness) =>
					handleSelectBusiness(selectedBusiness)
				}
				renderChildren={renderBusinessItem}
				onAddNewClick={onAddNewClick}
				listLabel={"Select Business"}
				onClose={onClose}
			/>
		</div>
	);
};

export default BusinessList;
