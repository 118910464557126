import { ApiService } from "src/network/apiService";
import { BusinessModel } from "src/types/models/apiModels";

export class NewBusinessViewModel {
  private apiService: ApiService;

  constructor(accessToken: string) {
    this.apiService = new ApiService({ accessToken: accessToken });
  }

  async validateBusiness(business: BusinessModel): Promise<string[]> {
    const errors: string[] = [];
    if (!business.primaryContact) {
      errors.push("primaryContact is required");
    }
    if (
      !business.details ||
      !business.details.fields ||
      business.details.fields.length === 0
    ) {
      errors.push("business details are required");
    }
    if (!business.info) {
      errors.push("business Info required");
    }
    return errors;
  }

  async saveBusiness(
    business: BusinessModel
  ): Promise<{ result: boolean; error?: string }> {
    const additionalDetails = business.details?.fields.map((p: any) => ({
      name: p.name,
      value: p.value,
    }));
    const numbers = business.numbers.map((p: any) => ({
      businessNumber: p.number,
      ownershipDocuments: p.ownershipDocuments,
    }));
    const input = {
      businessDetails: {
        name: business.info?.name,
        countryCode: business.info?.countryCode,
        industry: "NA",
        dataIsolation: false,
        website: business.info?.website,
        dunsNumber: business.info?.dunsNumber,
        logo: business.info?.logo
          ? {
            signedUrl: business?.info?.logo?.signedUrl,
            fileName: business?.info?.logo?.fileName,
          }
          : undefined,
      },
      additionalDetails: additionalDetails,
      businessNumbers: numbers,
    };

    try {
      const response: any = await this.apiService.postAsync(
        "/onboard/business/submit",
        input
      );

      if (response) {
        if (response.scResponse && response.scResponse.code === 2000) {
          return { result: true };
        } else {
          return { result: false, error: response.scResponse?.message };
        }
      }
    } catch (error) {
      return {
        result: false,
        error: "There was an error performing the operation",
      };
    }

    return {
      result: true,
      error: "There was an error performing the operation",
    };
  }

  async initBusiness(business?: BusinessModel): Promise<BusinessModel> {
    const initBus: BusinessModel = {
      primaryContact: business?.primaryContact ?? {
        firstName: "",
        lastName: "",
        emailId: "",
        phoneNumber: { countryCode: "", number: "" },
      },
      details: business?.details ? business.details : { fields: [] },
      info: business?.info,
      numbers: business?.numbers ?? [],
    };
    return initBus;
  }
}
