import React, { useEffect, useState } from "react";
import { TextField, Typography } from "@mui/material";

interface EditableLabelProps {
  text: string;
  onTextChange: (newText: string) => void;
  color?: string; // Optional color prop
}

const EditableLabel: React.FC<EditableLabelProps> = ({
  text,
  onTextChange,
  color = "inherit", // Default color is "inherit"
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentText, setCurrentText] = useState(text);

  useEffect(() => {
    setCurrentText(text);
  }, [text]);
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentText(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onTextChange(currentText);
  };

  return isEditing ? (
    <TextField
      value={currentText}
      onChange={handleTextChange}
      onBlur={handleBlur}
      variant="standard" // Use standard variant to remove background
      size="small"
      autoFocus
      fullWidth
      InputProps={{
        style: {
          backgroundColor: "white",
          color: "black",
          padding: 0, // Remove padding to align with Typography
          fontSize: "inherit", // Match Typography font size
          fontWeight: "inherit", // Match Typography font weight
          borderRadius: "4px",
          alignContent: "left",
          justifyContent: "left",
          textAlign: "left",
        },
        disableUnderline: true, // Remove the underline for a seamless look
        placeholder: "start typing here...",
      }}
    />
  ) : (
    <Typography
      variant="subtitle1"
      noWrap
      onClick={() => setIsEditing(true)}
      align="left"
      sx={{
        color,
        cursor: "pointer", // Indicate that the text is editable
      }}
    >
      {currentText}
    </Typography>
  );
};

export default EditableLabel;
