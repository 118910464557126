import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, ListItemAvatar, ListItemText } from "@mui/material";

import { useAuth } from "../auth/auth-context";
import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import EntityList from "../entity-list/entity-list";
import StatusChip from "src/components/atoms/status-chip/status-chip";
import { BusinessNumbersListProps } from "./business-numbers-list.props";
import { BusinessNumbersListViewModel } from "./business-numbers.view-model";
import { BusinessNumberModel } from "src/types/models/apiModels";

const BusinessNumbersList: React.FC<BusinessNumbersListProps> = ({
	onAddNewClick,
	selectMode,
	onSelect,
	businessId,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook
	const [data, setData] = useState<BusinessNumberModel[]>([]);
	const [loading, setLoading] = useState(true);
	const viewModel = useRef<BusinessNumbersListViewModel | null>(null);
	const [selectedNumber, setSelectedNumber] = useState<string | null>(null);

	const handleSelectNumber = (number: BusinessNumberModel) => {
		setSelectedNumber(number.businessNumber);
		if (onSelect) {
			onSelect(number);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new BusinessNumbersListViewModel(
						user.accessToken
					);
					const response =
						await viewModel.current?.fetchList(businessId);
					console.log("fetchData", response);
					setData(() => response);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};

		fetchData();
	}, [user]);

	const renderBusinessItem = (number: BusinessNumberModel) => (
		<>
			<ListItemAvatar>
				<Avatar src={number?.imageFile?.signedUrl} />
			</ListItemAvatar>
			<ListItemText
				primary={
					<SCLabel
						color="#000"
						fontWeight="normal"
						align="left"
						textTransform="none"
					>
						{number.businessNumber}
					</SCLabel>
				}
				primaryTypographyProps={{
					sx: {
						color:
							selectedNumber === number.businessNumber
								? "white"
								: "inherit",
						fontWeight:
							selectedNumber === number.businessNumber
								? "bold"
								: "normal",
					},
				}}
			/>
			<ListItemText
				primary={
					<>
						<StatusChip status={number.status} />
						<Box component="span" sx={{ mx: 1 }}>
							({number.defaultIntent})
						</Box>
					</>
				}
				primaryTypographyProps={{
					sx: {
						color:
							selectedNumber === number.businessNumber
								? "white"
								: "inherit",
						fontWeight:
							selectedNumber === number.businessNumber
								? "bold"
								: "normal",
					},
				}}
			/>
		</>
	);
	if (loading) {
		return (
			<LoadingPlaceHolder message="Loading your business data, please hold on!" />
		); // Pass the optional message
	}

	return (
		<EntityList<BusinessNumberModel>
			data={data}
			selectMode={selectMode} // or false depending on your requirements
			onSelect={(selectedBusiness) =>
				handleSelectNumber(selectedBusiness)
			}
			renderChildren={renderBusinessItem}
			onAddNewClick={onAddNewClick}
			listLabel={"Choose Your Number"}
		/>
	);
};

export default BusinessNumbersList;
