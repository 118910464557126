import { Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditableLabel from "src/components/atoms/editableLabel/editableLable";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import { CallAlertProps } from "./callAlert.props";

const CallAlert: React.FC<CallAlertProps> = ({
  alertContent,
  image,
  headerLabel,
  onChange,
}) => {
  return (
    <>
      <SCLabel color="#ff344">{headerLabel}</SCLabel>
      <Card
        sx={{
          background: "linear-gradient(to bottom, #353535, #515151)",
          display: "flex",
          alignItems: "center",
          padding: "16px",
          backgroundColor: "#3a3a3a",
          borderRadius: "16px",
          minWidth: "300px",
          width: "300px",
          maxHeight: "75px",
          margin: "auto",
        }}
      >
        <img
          src={image}
          alt="Bank Logo"
          style={{
            width: "40px",
            height: "40px",
            marginRight: "16px",
            borderRadius: "8px",
          }}
        />
        <CardContent>
          <EditableLabel
            text={alertContent?.title ?? ""}
            onTextChange={(value) => onChange("title", value)}
            color="#ffffff"
          />
          <EditableLabel
            text={alertContent?.body ?? ""}
            onTextChange={(value) => onChange("body", value)}
            color="#ffffff"
          />
        </CardContent>
      </Card>
    </>
  );
};

export default CallAlert;
