import { Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import Banner from "src/components/molecules/banner/Banner";
import PhoneNumberField from "src/components/molecules/phoneNumberField/PhoneNumberField";
import "./add-outgoing-number.css"; // Add your custom CSS here
import { AddOutgoingNumberProps } from "./add-outgoing-number.props";
import { useAuth } from "../auth/auth-context";
import { AddOutgoingNumberViewModel } from "./add-outgoing-number.view-model";
import UploadButton from "src/components/molecules/upload-button/upload-button";
import { IFileInfo, IPhoneNumber } from "src/types/models/apiModels";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import ButtonPro from "src/components/atoms/primary-button/primary-button";

const AddOutgoingNumber: React.FC<AddOutgoingNumberProps> = ({
	onAdd,
	fileInfo,
}) => {
	const [fileSrc, setFileSrc] = useState<IFileInfo | undefined>(fileInfo);
	const { user } = useAuth();
	const [number, setNumber] = useState<IPhoneNumber>({
		countryCode: "",
		number: "",
	});
	const [outgoingNumber, setOutgoingNumber] = useState("");
	const [fileName, setFileName] = useState("");

	const viewModel = useRef<AddOutgoingNumberViewModel | null>(null);

	useEffect(() => {
		if (user && user.accessToken) {
			viewModel.current = new AddOutgoingNumberViewModel(
				user.accessToken
			);
		}
	}, [user]);

	useEffect(() => {
		// console.log("###### imageSrc-useEffect", fileSrc);
		if (fileSrc?.fileName) {
			setFileName(fileSrc.fileName);
		}
	}, [fileSrc]);

	const handleFileChange = async (uploadedImage: IFileInfo) => {
		if (uploadedImage) {
			// console.log("#####uploadedFile", uploadedImage);
			setFileSrc(() => uploadedImage);
		}
	};

	const handleAdd = () => {
		console.log(
			`handleAdd: outgoingNumber: ${outgoingNumber}, file`,
			fileSrc
		);
		if (outgoingNumber && fileSrc) {
			// console.log("calling onAdd");
			onAdd(outgoingNumber, fileSrc);
		}
	};

	return (
		<Banner label="OUTGOING NUMBER">
			<Grid
				container
				direction="column"
				className="outgoing-number-container"
			>
				<Grid item>
					<PhoneNumberField
						phoneNumber={number}
						value={outgoingNumber}
						onChange={(phoneNumber, value) =>
							setOutgoingNumber(value)
						}
					></PhoneNumberField>
				</Grid>
				<Grid item>
					<br></br>
					<SCLabel align="left" color="#000">
						UPLOAD PROOF OF OWNERSHIP
					</SCLabel>
					<br></br>
					<Grid container alignItems="center" spacing={2}>
						<Grid item>
							<UploadButton
								buttonType="File"
								onUploadSuccess={handleFileChange}
							></UploadButton>
						</Grid>
						{fileName && (
							<Grid item>
								<SCLabel align="left" color="#000">
									Uploaded file
								</SCLabel>
								<Typography>
									{fileName || "No file selected"}
								</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
				<br></br>
				<Grid item>
					<ButtonPro
						label={"add"}
						onClick={handleAdd}
						disabled={!outgoingNumber || !fileSrc}
					></ButtonPro>
				</Grid>
			</Grid>
		</Banner>
	);
};

export default AddOutgoingNumber;
