import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../auth/auth-context";
import {
	Avatar,
	Box,
	Dialog,
	DialogContent,
	Divider,
	FormControlLabel,
	IconButton,
	Switch,
} from "@mui/material";

import PrimaryButton from "src/components/atoms/button/Primary-Button";
import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import ErrorPopup from "../error-popup/error-popup";
import "./application-add-new.css";
import { ApplicationAddNewProps } from "./application-add-new.props";
import { NewApplicationViewModel } from "./application-add-new.view-model";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import BusinessList from "../business-list/business-list";
import HelpText from "src/components/atoms/help-text/help-text";
import ButtonAction from "src/components/atoms/button-action/button-action";
import ContactCentreList from "../contact-centre-list/contact-centre-list";
import HelpIcon from "@mui/icons-material/Help";
import MobileAppList from "../mobile-app-list/mobile-app-list";
import ConfigureBranding from "../configureBranding/configureBranding";
import Dropdown from "src/components/atoms/dropdown/dropdown";

import BrandingOption from "src/components/molecules/branding-option/branding-option";
import BusinessNumbersList from "../business-numbers-list/business-numbers-list";
import BusinessNumbersConfigure from "../business-numbers-configure/business-numbers-configure";
import {
	ApplicationModel,
	BusinessListModel,
	BusinessNumberModel,
	ContactCentreModel,
	MobileAppModel,
} from "src/types/models/apiModels";
import { TBrandingOptions } from "src/types/models/common";
import ApplicationCredentials from "../application-credentials/application-credentials";
import ButtonPro, {
	ButtonVariant,
} from "src/components/atoms/primary-button/primary-button";

const ApplicationAddNew: React.FC<ApplicationAddNewProps> = ({
	onSubmitClick,
	onCancelClick,
	editApplicationModel,
}) => {
	const defaultCC = {
		contactCentreId: "NA",
		contactCentreCode: "NA",
		productName: "NA",
		name: "Not Applicable",
		whitelistedUrls: [],
	};

	const { user } = useAuth(); // Extract user from useAuth hook
	const [loading, setLoading] = useState(false);
	const [business, setBusiness] = useState<BusinessListModel | undefined>(
		undefined
	);
	const [contactCentre, setContactCentre] = useState<
		ContactCentreModel | undefined
	>(undefined);
	const [contactCentreId, setContactCentreId] = useState<string>("NA");
	const [brandingOption, setBrandingOption] = useState<
		TBrandingOptions | undefined
	>();
	const [contactCentreList, setContactCentreList] = useState<
		ContactCentreModel[]
	>([defaultCC]);
	const [businessNumbers, setBusinessNumbers] = useState<
		BusinessNumberModel[]
	>([]);
	const [mobileApp, setMobileApp] = useState<MobileAppModel | undefined>(
		undefined
	);
	const [selectBusinessDialogOpen, setSelectBusinessDialogOpen] =
		useState<boolean>(false);
	const [selectCCDialogOpen, setSelectCCDialogOpen] =
		useState<boolean>(false);
	const [selectAppDialogOpen, setSelectAppDialogOpen] =
		useState<boolean>(false);
	const [brandingDialogOpen, setBrandingDialogOpen] =
		useState<boolean>(false);
	const [numberDialogOpen, setNumberDialogOpen] = useState<boolean>(false);
	// const [businessList, setBusinessList] = useState<BusinessListModel[]>([]);
	const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
	const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
	const [scProvidedNumbers, setSCProvidedNumbers] = useState(false);
	const [showApplicationCredentials, setShowApplicationCredentials] =
		useState(false);
	const [showHelp, setShowHelp] = useState(true);
	const [error, setError] = useState<string>("");
	const viewModel = useRef<NewApplicationViewModel | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new NewApplicationViewModel(
						user.accessToken
					);
					const businessList =
						await viewModel.current?.fetchBusinessList();

					if (businessList && businessList.length === 1) {
						setBusiness(() => businessList[0]);
					}

					const appList =
						await viewModel.current?.fetchMobileAppList();

					if (appList && appList.length === 1) {
						setMobileApp(() => appList[0]);
					}

					const ccList =
						await viewModel.current?.fetchContactCentreList();

					if (ccList) {
						ccList.push(defaultCC);
						setContactCentreList(() => ccList);
					}
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};
		fetchData();
	}, [user]);

	useEffect(() => {
		const fetchData = async () => {
			const cc = contactCentreList.find(
				(p) => (p.contactCentreId = contactCentreId)
			);
			setContactCentre(() => cc);
		};
		fetchData();
	}, [contactCentreId]);

	const handleSubmitApplication = async () => {
		try {
			if (user && user.accessToken) {
				viewModel.current = new NewApplicationViewModel(
					user.accessToken
				);
				if (mobileApp && business && brandingOption) {
					const request: ApplicationModel = {
						countryCode: "AU",
						brandingApplicationId: mobileApp?.id,
						contactCentreId: contactCentre?.contactCentreId ?? "",
						businessId: business?.businessId,
						businessUnitId: "",
						businessCode: business.businessCode,
						brandingOption: brandingOption,
						businessNumbers: businessNumbers.map((item) => ({
							businessNumber: item.businessNumber,
							brandingName: item.brandingName || "", // Provide default empty string if undefined
							defaultIntent: item.defaultIntent || "", // Provide default empty string if undefined
							defaultTTLInMinutes: item.defaultTTLInMinutes || 10, // Provide default value 0 if undefined
							imageFile: item.imageFile,
							setupPushContent: item.setupPushContent || {
								title: "",
								body: "",
							}, // Provide default empty AlertContent if undefined
							cleanupPushContent: item.cleanupPushContent || {
								title: "",
								body: "",
							}, // Provide default empty AlertContent if undefined
							managedNumberSettings:
								brandingOption === "CPaaSManagedCallBranding"
									? {
											numberProvider: "Vonage",
											screenSettings: {
												appLogo: {
													size: {
														width: 200,
														height: 100,
													},
												},
												otherButton: {
													color: {
														background: "#484848",
														foreground: "#FEFEFE",
													},
												},
												endCallButton: {
													color: {
														background: "#D70013",
														foreground: "#FEFEFE",
													},
												},
												answerCallButton: {
													color: {
														background: "#248A3D",
														foreground: "#FEFEFE",
													},
												},
												background: {
													color: "#2B2B2B",
												},
											},
											sipEndpointUrl:
												"sip:12037796621@securedcalls.3cx.com.au:5060",
											alwaysUseDefaultIntent: false,
											outGoingOnMobileOption: "Fallback",
										}
									: undefined, // This is optional, pass as-is
							scProvidedNumber: item.scProvidedNumber, // Pass as-is
						})),
						scProvidedNumbers: scProvidedNumbers,
					};
					const saveResult =
						await viewModel.current?.submitApplication(request);
					console.log("response", saveResult);
					if (saveResult?.result === true) {
						// onSubmitClick(business);
						setShowApplicationCredentials(true);
					} else {
						setError(() => saveResult?.error ?? "");
						toggleError(true);
					}
				}
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		} finally {
			setLoading((prevValue) => {
				if (prevValue) {
					return false;
				}
				return prevValue;
			});
		}
	};

	const handleConfirmSubmitBusiness = async () => {
		console.log("In confirmSubmitBusinessCallBack", business);
		if (business) {
		}
	};

	const toggleError = (value: boolean) => {
		console.log("In toggleError");
		setErrorDialogOpen((prevValue) => {
			if (prevValue !== value) {
				return value;
			}
			return prevValue;
		});
	};

	const handleSCProvidedNumbersToggle = (event: any) => {
		setSCProvidedNumbers(event.target.checked);
		console.log(
			"SIP Number via Secured Calls:",
			event.target.checked ? "Enabled" : "Disabled"
		);
	};

	const closeError = () => {
		console.log("In closeError");
		toggleError(false);
	};

	const closeConfirm = () => {
		setConfirmDialogOpen((prevValue) => {
			if (prevValue === true) {
				return false;
			}
			return prevValue;
		});
	};

	const toggleHelp = () => {
		setShowHelp((prev) => !prev);
	};

	const handleCancel = () => {
		console.log("In handleCancel");
		if (onCancelClick) {
			onCancelClick();
		}
	};

	const toggleBusinessDialog = () => {
		console.log("In toggleBusinessDialog");
		setSelectBusinessDialogOpen((prevValue) => !prevValue);
	};

	const toggleCCDialog = () => {
		console.log("In toggleCCDialog");
		setSelectCCDialogOpen((prevValue) => !prevValue);
	};

	const toggleAppDialog = () => {
		console.log("In toggleAppDialog");
		setSelectAppDialogOpen((prevValue) => !prevValue);
	};

	const toggleBrandingDialog = () => {
		console.log("In toggleBrandingDialog");
		setBrandingDialogOpen((prevValue) => !prevValue);
	};

	const toggleNumberDialog = () => {
		console.log("In toggleBrandingDialog");
		setNumberDialogOpen((prevValue) => !prevValue);
	};

	if (loading) {
		return (
			<LoadingPlaceHolder message="We're setting things up for you. Just a moment!" />
		);
	}

	function handleOnAddBusiness(): void {
		throw new Error("Function not implemented.");
	}
	function handleOnNumbersSelected(numbers: BusinessNumberModel[]): void {
		setBusinessNumbers(() => numbers);
	}
	function handleOnSelectBusiness(business: BusinessListModel): void {
		console.log("handleOnSelectBusiness", handleOnSelectBusiness);
		setBusiness(business);
		toggleBusinessDialog();
	}

	function showBusinessList(): void {
		toggleBusinessDialog();
	}

	function handleOnSelectCC(contactCentre: ContactCentreModel): void {
		console.log("handleOnSelectBusiness", handleOnSelectBusiness);
		setContactCentre(contactCentre);
		toggleCCDialog();
	}
	function handleOnSelectNumber(number: BusinessNumberModel): void {
		console.log("handleOnSelectNumber", number);
		toggleNumberDialog();
	}
	function showCCList(): void {
		toggleCCDialog();
	}

	function handleOnSelectApp(mobileApp: MobileAppModel): void {
		console.log("handleOnSelectBusiness", handleOnSelectBusiness);
		setMobileApp(mobileApp);
		toggleAppDialog();
	}

	function showAppList(): void {
		toggleAppDialog();
	}

	function showBrandingPopup(): void {
		toggleBrandingDialog();
	}
	function showNumbersPopup(): void {
		toggleNumberDialog();
	}
	function handleContactCentreChange(value: string): void {
		setContactCentreId(value);
	}

	function handleNumberOptionsChange(value: TBrandingOptions): void {
		setBrandingOption(value);
	}

	return (
		<Box
			className="main-content"
			sx={{
				flexGrow: 1,
				padding: 3,
				marginLeft: `10px`,
				transition: "margin-left 0.3s ease",
			}}
		>
			{/* Help Text */}
			{showHelp === true && (
				<HelpText
					help={[
						<>
							Time to set up your first application! Before diving
							in, make sure you’ve created a{" "}
							<a href="/business">business</a> and had it
							approved, that your numbers are also approved, and
							that your mobile apps are registered. Additionally,
							ensure your contact center is configured with
							whitelisted URLs.
						</>,
						"It’s a comprehensive branding configuration that lets you specify which numbers will be branded using which mobile application and which contact center will handle those numbers. You can also assign branding elements like intent, default TTL, and images",
						"You can include multiple numbers in a single application, but note that each number can only be part of one application. For scenarios where you need different configurations for the same business on the same mobile app and contact center—but with different settings like intent, TTL, or fallback options—you can do so by creating multiple applications",
					]}
					alignment="center"
					onCloseClick={toggleHelp}
				/>
			)}

			{showHelp === false && (
				<Box
					sx={{ position: "relative", marginBottom: 3, marginTop: 3 }}
				>
					<IconButton
						onClick={toggleHelp}
						sx={{ position: "absolute", top: 0, right: 0 }}
					>
						<HelpIcon />
					</IconButton>
				</Box>
			)}

			<Box className="form-container">
				{business && (
					<>
						<SCLabel color="#000" align="left">
							Business
						</SCLabel>
						<Box
							sx={{
								borderRadius: 1,
								marginBottom: 1,
								padding: 1,
								display: "flex",
								alignItems: "center",
								marginTop: 1, // Adds space between the list and the selected item display
							}}
							onClick={showBusinessList}
						>
							<Avatar src={business?.logo?.signedUrl} />
							<Box sx={{ ml: 2 }}>
								<SCLabel
									color="#000"
									fontWeight="normal"
									align="left"
									textTransform="none"
								>
									{business?.businessCode}: {business?.name}
								</SCLabel>
							</Box>
						</Box>
						<Divider sx={{ marginBottom: 3 }} />
					</>
				)}
				{!business && (
					<ButtonAction
						onClick={showBusinessList}
						label={"Select Business"}
					></ButtonAction>
				)}

				{business && !mobileApp && (
					<ButtonAction
						onClick={showAppList}
						label={"Select mobile app"}
					></ButtonAction>
				)}
				{business && mobileApp && (
					<>
						<SCLabel color="#000" align="left">
							Mobile application
						</SCLabel>
						<Box
							sx={{
								borderRadius: 1,
								marginBottom: 1,
								padding: 1,
								display: "flex",
								alignItems: "center",
								marginTop: 1, // Adds space between the list and the selected item display
							}}
							onClick={showCCList}
						>
							<Box sx={{ ml: 2 }}>
								<SCLabel
									color="#000"
									fontWeight="normal"
									align="left"
									textTransform="none"
								>
									{mobileApp?.name} ({mobileApp?.code}) (
									{mobileApp?.iOSAppSettings?.bundleId}{" "}
									{mobileApp?.androidAppSettings?.packageName}
									)
								</SCLabel>
							</Box>
						</Box>

						<SCLabel color="#000" align="left">
							Contact centre
						</SCLabel>
						<Box
							sx={{
								borderRadius: 1,
								marginBottom: 1,
								padding: 1,
								display: "flex",
								alignItems: "center",
								marginTop: 1, // Adds space between the list and the selected item display
							}}
						>
							<Dropdown
								label="Country code"
								value={contactCentre?.contactCentreId ?? "NA"}
								onChange={(value) =>
									handleContactCentreChange(value)
								}
								menuItems={contactCentreList.map((cc) => ({
									value: cc.contactCentreId,
									label: `${cc.name} (${cc.contactCentreCode})`,
								}))}
							/>
						</Box>
						<SCLabel color="#000" align="left" marginTop={3}>
							Number Options
						</SCLabel>
						<Box
							sx={{
								border: "1px solid black",
								borderRadius: 1,
								marginBottom: 1,
								padding: 1,
								display: "flex",
								alignItems: "center",
								marginTop: 1, // Adds space between the list and the selected item display
							}}
						>
							<BrandingOption
								value={brandingOption}
								onChange={handleNumberOptionsChange}
							></BrandingOption>
						</Box>
					</>
				)}

				{business &&
					mobileApp &&
					brandingOption === "CPaaSManagedCallBranding" && (
						<>
							{" "}
							<Box
								sx={{
									borderRadius: 1,
									marginBottom: 1,
									padding: 1,
									display: "flex",
									alignItems: "center",
									marginTop: 1, // Adds space between the list and the selected item display
								}}
							>
								<FormControlLabel
									sx={{ alignContent: "start" }}
									control={
										<Switch
											onChange={
												handleSCProvidedNumbersToggle
											}
										/>
									}
									label="Provide a SIP number via Secured Calls"
								/>
							</Box>
						</>
					)}

				{business &&
					mobileApp &&
					((brandingOption === "CPaaSManagedCallBranding" &&
						scProvidedNumbers === false) ||
						brandingOption === "UnmanagedCallBranding") && (
						<Box>
							<SCLabel color="#000" align="left">
								Assign Numbers
							</SCLabel>

							<>
								{" "}
								<BusinessNumbersConfigure
									business={business}
									onAddNewClick={function (): void {
										throw new Error(
											"Function not implemented."
										);
									}}
									selectMode={true}
									scProvidedNumber={scProvidedNumbers}
									onSelect={handleOnNumbersSelected}
								></BusinessNumbersConfigure>
							</>
						</Box>
					)}

				<ErrorPopup
					message={error}
					open={errorDialogOpen}
					onClose={closeError}
				></ErrorPopup>
				{business && contactCentre && mobileApp && (
					<div className="flex flex-col gap-2 mt-6">
						<ButtonPro
							label={"SUBMIT"}
							onClick={handleSubmitApplication}
							disabled={!business || !mobileApp}
						></ButtonPro>

						<ButtonPro
							variant={ButtonVariant.Secondary}
							label={"CANCEL"}
							onClick={handleCancel}
						></ButtonPro>
					</div>
				)}
				{/* <ConfirmationPopup
          business={business}
          onConfirm={handleConfirmSubmitBusiness}
          open={confirmDialogOpen}
          onClose={closeConfirm}
        ></ConfirmationPopup> */}
			</Box>

			<Dialog
				open={selectBusinessDialogOpen}
				onClose={toggleBusinessDialog}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<BusinessList
						onAddNewClick={handleOnAddBusiness}
						onSelect={handleOnSelectBusiness}
						selectMode={true}
						withStatuses={["Approved", "Active"]}
					></BusinessList>
				</DialogContent>
			</Dialog>
			<Dialog
				open={selectCCDialogOpen}
				onClose={toggleCCDialog}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<ContactCentreList
						onAddNewClick={handleOnAddBusiness}
						onSelect={handleOnSelectCC}
						selectMode={true}
					></ContactCentreList>
				</DialogContent>
			</Dialog>
			<Dialog
				open={selectAppDialogOpen}
				onClose={toggleAppDialog}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<MobileAppList
						onAddNewClick={handleOnAddBusiness}
						onSelect={handleOnSelectApp}
						selectMode={true}
					></MobileAppList>
				</DialogContent>
			</Dialog>
			<Dialog
				open={numberDialogOpen}
				onClose={toggleNumberDialog}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<BusinessNumbersList
						businessId={business?.businessId ?? ""}
						onAddNewClick={function (): void {
							throw new Error("Function not implemented.");
						}}
					></BusinessNumbersList>
				</DialogContent>
			</Dialog>
			<Dialog
				open={brandingDialogOpen}
				onClose={toggleBrandingDialog}
				maxWidth="lg"
				fullWidth
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
						width: "100%",
						alignContent: "centre",
						justifyContent: "center",
					},
				}}
			>
				<DialogContent>
					<ConfigureBranding
						businessId={business?.businessCode ?? ""}
						businessName={business?.name ?? "placeholder"}
						defaultIntent="demo intent"
						number="1324354678"
						image={business?.logo?.signedUrl ?? ""}
					></ConfigureBranding>
				</DialogContent>
			</Dialog>

			<Dialog
				open={showApplicationCredentials}
				onClose={() => setShowApplicationCredentials(false)}
				maxWidth="md"
				fullWidth
				// PaperProps={{
				//   style: {
				//     backgroundColor: "transparent",
				//     boxShadow: "none",
				//     width: "100%",
				//     alignContent: "centre",
				//     justifyContent: "center",
				//   },
				// }}
			>
				<DialogContent>
					<ApplicationCredentials
						brandingApplicationCode=""
						ccId=""
						isNewApp={true}
						onOkClick={handleCancel}
					></ApplicationCredentials>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default ApplicationAddNew;
