import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";

import Banner from "src/components/molecules/banner/Banner";

import BusinessList from "../business-list/business-list";
import BusinessAddNew from "../business-add-new/business-add-new";
import { BusinessProps } from "./business-main.props";
import { BusinessModel } from "src/types/models/apiModels";

const Business: React.FC<BusinessProps> = ({ openInEditMode }) => {
	const [title, setTitle] = useState("Business List");
	const [editMode, setEditMode] = useState<boolean>(openInEditMode ?? false);

	{
		console.log("Current Edit Mode: ", editMode);
	}
	const addBusinessCallBack = useCallback(() => {
		console.log("addBusinessCallBack!");
		toggleEditMode();
	}, []); // No dependencies, so this callback won't change across renders

	const submitBusinessCallBack = useCallback((business: BusinessModel) => {
		console.log("addBusinessCallBack!", business);
		toggleEditMode();
	}, []);
	const cancelBusinessCallBack = useCallback(() => {
		console.log("cancelBusinessCallBack!");
		toggleEditMode();
	}, []);

	const toggleEditMode = () => {
		console.log("In toggleEditMode");
		setEditMode((prevValue) => !prevValue);
	};

	useEffect(() => {
		if (editMode) {
			setTitle("Add / Edit a Business");
		} else {
			setTitle("Business List");
		}
	}, [editMode]);

	// Functions to handle opening and closing the dialog

	return (
		<Box
			className="main-content"
			sx={{
				flexGrow: 1,
				padding: 3,
				marginLeft: `10px`,
				transition: "margin-left 0.3s ease",
			}}
		>
			<Banner label={title}>
				{editMode === false ? (
					<BusinessList
						onAddNewClick={addBusinessCallBack}
					></BusinessList>
				) : (
					<BusinessAddNew
						onSubmitClick={submitBusinessCallBack}
						onCancelClick={cancelBusinessCallBack}
					></BusinessAddNew>
				)}
			</Banner>
		</Box>
	);
};

export default Business;
