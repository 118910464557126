import React, { useEffect, useRef, useState } from "react";
import {
	Alert,
	Avatar,
	Box,
	Dialog,
	DialogContent,
	IconButton,
	ListItemAvatar,
	ListItemText,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@mui/material";

import Banner from "src/components/molecules/banner/Banner";
import { ReactComponent as DownloadIcon } from "../../../assets/download.svg";
import TableHeader from "src/components/molecules/table-header/table-header";
import ButtonAddNew from "src/components/atoms/button-add-new/button-add-new";
import AddOutgoingNumber from "../add-outgoing-number/add-outgoing-number";

import { BusinessNumbersProps } from "../business-numbers/business-numbers.props";
import BusinessList from "../business-list/business-list";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import StatusChip from "src/components/atoms/status-chip/status-chip";
import { NewBusinessNumberViewModel } from "./number-viewmodel";
import { useAuth } from "../auth/auth-context";
import {
	BusinessListModel,
	IFileInfo,
	NewNumberModel,
	ServiceStatus,
} from "src/types/models/apiModels";
import LoadingModal from "src/components/molecules/loading/LoadingModal";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Delete } from "@mui/icons-material";
import ButtonPro from "src/components/atoms/primary-button/primary-button";

const Numbers: React.FC<BusinessNumbersProps> = ({ editNumbers }) => {
	const { user } = useAuth();
	const viewModel = useRef<NewBusinessNumberViewModel>();
	const [loading, setLoading] = useState(false);
	const [business, setBusiness] = useState<BusinessListModel | undefined>(
		undefined
	);
	const [businessId, setBusinessId] = useState<string>("");
	const [selectBusinessDialogOpen, setSelectBusinessDialogOpen] =
		useState<boolean>(false);
	const [businessNumbers, setBusinessNumbers] = useState<NewNumberModel[]>(
		editNumbers ?? []
	);
	const [addNumberDialogOpen, setAddNumberDialogOpen] =
		useState<boolean>(false);
	const [numberAddError, setNumberAddError] = useState("");
	const [showNumberAddingError, setShowNumberAddingError] = useState(false);

	useEffect(() => {
		if (user && user.accessToken) {
			viewModel.current = new NewBusinessNumberViewModel(
				user?.accessToken
			);
		}
	}, []);

	const fetchData = async () => {
		if (businessId && viewModel.current) {
			setLoading(true);
			setNumberAddError("");
			setShowNumberAddingError(false);
			const data = await viewModel.current.getNumbers(businessId);
			if (data) {
				setBusinessNumbers(data);
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		fetchData(); // Call the async function inside useEffect
	}, [businessId]); // Dependency array ensures this runs when businessId changes

	const toggleBusinessDialog = () => {
		console.log("In toggleBusinessDialog");
		setSelectBusinessDialogOpen((prevValue) => !prevValue);
	};

	const toggleDialog = () => {
		setAddNumberDialogOpen((prevValue) => !prevValue);
	};

	function handleOnSelectBusiness(business: BusinessListModel): void {
		console.log("handleOnSelectBusiness", business.businessId);
		setBusiness(business);
		setBusinessId(business.businessId);
		// toggleBusinessDialog();
	}

	function showBusinessList(): void {
		toggleBusinessDialog();
	}

	function handleOnAddBusiness(): void {
		throw new Error("Function not implemented.");
	}

	const addNumber = (number: string, fileInfo: IFileInfo) => {
		console.log("In handleAddNumberCallback");
		setShowNumberAddingError(false);
		setNumberAddError("");

		setBusinessNumbers((prevNumbers) => {
			// Check if the number already exists in the list
			const numberExists = prevNumbers.some(
				(num) => num.number === number
			);

			if (numberExists) {
				// console.log("Number already exists. Update rejected.");
				setShowNumberAddingError(true);
				setNumberAddError("Number already exists. Update rejected.");
				// You can add more logic here, like showing an error message
				return prevNumbers; // Return the existing numbers without adding
			}

			const updatedNumbers = [
				...prevNumbers,
				{
					number: number,
					ownershipDocuments: [fileInfo],
					status: "Added" as ServiceStatus,
				},
			];

			return updatedNumbers;
		});

		setAddNumberDialogOpen(false);
	};

	const handleAddNewNumberClick = () => {
		setNumberAddError("");
		setShowNumberAddingError(false);
		setAddNumberDialogOpen(true);
	};

	const handleSubmit = async () => {
		// Filter the business numbers with status "New"
		const newBusinessNumbers = businessNumbers.filter(
			(number) => number.status === "Added"
		);

		// Ensure that business and businessId exist
		if (business && business.businessId) {
			setLoading(true);
			const result = await viewModel.current?.submitBusinessNumbers(
				business.businessId,
				newBusinessNumbers
			);

			if (result && result.result) {
				const failedNumbers = result.result.businessNumbers.filter(
					(number) => number.success === false
				);

				if (failedNumbers.length > 0) {
					let errorString = "";
					failedNumbers.map((failedNumber) => {
						errorString = errorString + " " + failedNumber.message;
					});

					setNumberAddError(errorString);
					setShowNumberAddingError(true);
				}
			}

			console.log(JSON.stringify(result));

			if (result) {
				const data = await viewModel.current?.getNumbers(businessId);
				if (data) {
					setBusinessNumbers(data);
				}
			}
			setLoading(false);
			// console.log(`Result of the operation = ${JSON.stringify(result)}`);
		}
	};

	const handleRemoveNumber = async (number: string) => {
		const updatedItems = businessNumbers.filter(
			(item) => item.number !== number
		);

		setBusinessNumbers(updatedItems);
	};

	return (
		<Box
			className="main-content"
			sx={{
				flexGrow: 1,
				padding: 3,
				marginLeft: `10px`,
				transition: "margin-left 0.3s ease",
			}}
		>
			<Banner label="NUMBER LIST">
				<div className="bg-white p-12 rounded-lg drop-shadow-2xl">
					<SCLabel color="#000">
						{business ? (
							<span
								onClick={showBusinessList}
								style={{
									display: "flex",
									cursor: "pointer",
									backgroundColor: "#E1E1E1",
									padding: "12px",
								}}
							>
								<ListItemAvatar>
									<Avatar
										sx={{
											width: "75px",
											height: "75px",
											marginRight: 2,
										}}
										src={business.logo.signedUrl}
									/>
								</ListItemAvatar>
								<ListItemText
									primary={
										<div className="w-full flex items-center justify-start gap-2">
											<span className="text-lg font-bold">
												{business.name}
											</span>
											<span className="text-sm font-bold">
												({business.businessCode})
											</span>
										</div>
									}
									secondary={
										<StatusChip status={business.status} />
									}
									primaryTypographyProps={{
										sx: {
											fontWeight: "normal",
										},
									}}
									secondaryTypographyProps={{
										sx: {
											textAlign: "left", // Ensures that the secondary element is left-aligned
										},
									}}
								/>
							</span>
						) : (
							<button
								onClick={showBusinessList}
								style={{
									display: "flex",
									cursor: "pointer",
									color: "blue",
									background: "none",
									border: "none",
									textDecoration: "underline",
								}}
							>
								<SCLabel color="">Select a business</SCLabel>
							</button>
						)}
					</SCLabel>
					<br></br>
					<Table
						sx={{
							minWidth: 650,
							borderCollapse: "collapse",
						}}
					>
						<TableHeader
							headers={[
								"OUTGOING NO.",
								"PROOF OF OWNERSHIP",
								"STATUS",
								"ACTION",
							]}
						></TableHeader>
						<TableBody>
							{businessNumbers.map((row, index) => (
								<TableRow key={index}>
									<TableCell sx={{ textAlign: "center" }}>
										<SCLabel color="#000" align="center">
											{row.number}
										</SCLabel>
									</TableCell>
									<TableCell sx={{ textAlign: "center" }}>
										<Box
											sx={{
												display: "flex",
												gap: 1,
												alignContent: "center",
												justifyContent: "center",
											}}
										>
											<IconButton
												size="small"
												color="primary"
											>
												<DownloadIcon />
											</IconButton>
										</Box>
									</TableCell>
									<TableCell sx={{ textAlign: "center" }}>
										<SCLabel color="#000" align="center">
											{row.status}
										</SCLabel>
									</TableCell>
									<TableCell
										sx={{
											alignItems: "center",
											justifyContent: "center",
											textAlign: "center",
										}}
									>
										{row.status === "Added" && (
											<IconButton
												size="small"
												onClick={() =>
													handleRemoveNumber(
														row.number
													)
												}
											>
												<Delete color="primary" />
											</IconButton>
										)}
										{row.status !== "Added" && (
											<NotInterestedIcon color="error" />
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					{showNumberAddingError && (
						<>
							<br></br>
							<Alert severity="error">{numberAddError}</Alert>
						</>
					)}

					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: 2,
							marginBottom: 2,
						}}
					>
						<ButtonAddNew
							disabled={!business}
							onClick={handleAddNewNumberClick}
						></ButtonAddNew>
					</Box>
					<ButtonPro
						label={"SUBMIT"}
						onClick={handleSubmit}
						disabled={
							!business ||
							businessNumbers.filter(
								(number) => number.status === "Added"
							).length === 0
						}
					></ButtonPro>
				</div>
			</Banner>
			{/* loader */}
			<LoadingModal open={loading}></LoadingModal>
			{/* Add number popup */}
			<Dialog
				open={addNumberDialogOpen}
				onClose={toggleDialog}
				maxWidth="sm"
				fullWidth
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
					},
				}}
			>
				<DialogContent>
					<AddOutgoingNumber onAdd={addNumber}></AddOutgoingNumber>
				</DialogContent>
			</Dialog>
			{/* Select business pop-up */}
			<Dialog
				open={selectBusinessDialogOpen}
				onClose={toggleBusinessDialog}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<BusinessList
						onAddNewClick={handleOnAddBusiness}
						onSelect={handleOnSelectBusiness}
						selectMode={true}
						onClose={toggleBusinessDialog}
					></BusinessList>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default Numbers;
