import CancelIcon from "@mui/icons-material/Cancel";
import { Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import Banner from "src/components/molecules/banner/Banner";
import "./upload-file.css"; // Add your custom CSS here
import { UploadFileProps } from "./upload-file.props";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import { useAuth } from "../auth/auth-context";
import { UploadFileViewModel } from "./upload-file.view-model";
import UploadButton from "src/components/molecules/upload-button/upload-button";
import { ReactComponent as DownloadIcon } from "../../../assets/download.svg";
import { IFileInfo } from "src/types/models/apiModels";

const UploadFile: React.FC<UploadFileProps> = ({
	label,
	onUploadSuccess,
	onClear,
	file,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook
	const [fileSrc, setFileSrc] = useState<IFileInfo | undefined>(undefined);
	const viewModel = useRef<UploadFileViewModel | null>(null);
	useEffect(() => {
		if (user && user.accessToken) {
			viewModel.current = new UploadFileViewModel(user.accessToken);
		}
	}, [user]);

	useEffect(() => {
		console.log("###### fileSrc-useEffect", fileSrc);
		onUploadSuccess(fileSrc);
	}, [fileSrc]);

	const handleFileChange = async (uploadedFile: IFileInfo) => {
		if (uploadedFile) {
			console.log("#####uploadedFile", uploadedFile);
			setFileSrc(() => uploadedFile);
		}
	};

	const handleClear = async () => {
		setFileSrc(() => undefined);
		if (onClear) {
			onClear();
		}
	};

	return (
		<Grid
			container
			direction="column"
			className="outgoing-number-container"
		>
			<Grid item>
				<br></br>
				<SCLabel color="#000" align="left">
					{label}
				</SCLabel>
				<br></br>
				<Grid container alignItems="center" spacing={2}>
					<Grid item>
						<UploadButton
							buttonType="File"
							onUploadSuccess={handleFileChange}
							onClear={handleClear}
						></UploadButton>
					</Grid>
					<Grid item>
						{fileSrc && (
							<>
								<IconButton size="small" color="primary">
									<DownloadIcon />
								</IconButton>
								{fileSrc?.fileName}
							</>
						)}
					</Grid>
				</Grid>
			</Grid>

			{/* <Grid item>
        <PrimaryButton
          variant="outlined"
          color="info"
          label="ADD"
          cssClassName="primary-button"
          disabled={uploading || !outgoingNumber || !file}
          onClick={handleAdd}
        ></PrimaryButton>
      </Grid> */}
		</Grid>
	);
};

export default UploadFile;
