import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { MobileAppProps } from "./mobile-app.props";
import "./mobile-app.css";
import Banner from "src/components/molecules/banner/Banner";
import MobileAppList from "../mobile-app-list/mobile-app-list";
import MobileAppAddNew from "../mobile-app-add-new/mobile-app-add-new";
import { MobileAppModel } from "src/types/models/apiModels";

const MobileApp: React.FC<MobileAppProps> = ({ openInEditMode }) => {
	const [editMode, setEditMode] = useState<boolean>(openInEditMode ?? false);
	const [title, setTitle] = useState("Mobile App List");
	const [mobileApp, setMobileApp] = useState<MobileAppModel | undefined>(
		undefined
	);
	{
		console.log("Current Edit Mode: ", editMode);
	}

	const handleSave = useCallback((mobileApp: MobileAppModel) => {
		console.log("handleSave!", mobileApp);
		toggleEditMode();
	}, []);

	const handleOnCancel = useCallback(() => {
		// console.log("cancelBusinessCallBack!");
		toggleEditMode();
	}, []);

	const handleOnAdd = useCallback(() => {
		// console.log("cancelBusinessCallBack!");
		toggleEditMode();
	}, []);

	useEffect(() => {
		if (editMode) {
			setTitle("Add / Edit Mobile App");
		} else {
			setTitle("Mobile App List");
			setMobileApp(undefined);
		}
	}, [editMode]);

	const handleSelectedApp = async (selectedApp: MobileAppModel) => {
		setMobileApp(selectedApp);
		toggleEditMode();
		console.log(`selected mobile app ${selectedApp.name}`);
	};

	const toggleEditMode = () => {
		console.log("In toggleEditMode");
		setEditMode((prevValue) => !prevValue);
	};

	return (
		<Box
			className="main-content"
			sx={{
				flexGrow: 1,
				padding: 3,
				marginLeft: `10px`,
				transition: "margin-left 0.3s ease",
			}}
		>
			<Banner label={title}>
				<div className="bg-white p-12 rounded-lg drop-shadow-2xl">
					{editMode === false ? (
						<MobileAppList
							onSelect={(app) => handleSelectedApp(app)}
							onAddNewClick={handleOnAdd}
						></MobileAppList>
					) : (
						<MobileAppAddNew
							mobileApp={mobileApp}
							onSave={handleSave}
							onCancel={handleOnCancel}
						></MobileAppAddNew>
					)}
				</div>
			</Banner>
		</Box>
	);
};

export default MobileApp;
