import React from "react";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ButtonAddNewProps } from "./button-add-new.props";
import "./button-add-new.css";

const ButtonAddNew: React.FC<ButtonAddNewProps> = ({
	onClick,
	disabled = false,
}) => {
	return (
		<Button
			variant="text"
			onClick={onClick}
			startIcon={<AddCircleOutlineIcon />}
			className="add-new-button drop-shadow-lg"
			disabled={disabled}
		>
			ADD NEW
		</Button>
	);
};

export default ButtonAddNew;
