import { Box } from "@mui/material";
import "./CopyRight.css";

const CopyRight: React.FC = () => {
	return (
		<span className="text-white text-sm pb-12">
			© 2024 Expertstack Pty Ltd | All Rights Reserved.
		</span>
	);
};

export default CopyRight;
