import React, { useEffect, useRef, useState } from "react";
import { Box, ListItemText, Typography } from "@mui/material";
import { useAuth } from "../auth/auth-context";
import { MobileAppListProps } from "./mobile-app-list.props";
import { MobileAppListViewModel } from "./mobile-app-list.view-model";
import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import EntityList from "../entity-list/entity-list";
import { MobileAppModel } from "src/types/models/apiModels";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";

const MobileAppList: React.FC<MobileAppListProps> = ({
	onAddNewClick,
	selectMode,
	onSelect,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook
	const [data, setData] = useState<MobileAppModel[]>([]);
	const [loading, setLoading] = useState(true);
	const viewModel = useRef<MobileAppListViewModel | null>(null);
	const [selectedMobileApp, setSelectedMobileApp] = useState<string | null>(
		null
	);

	const handleSelectMobileApp = (mobileApp: MobileAppModel) => {
		if (selectMode) {
			setSelectedMobileApp(mobileApp.id);
		}
		if (onSelect) {
			onSelect(mobileApp);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new MobileAppListViewModel(
						user.accessToken
					);
					const response = await viewModel.current?.fetchList();
					console.log("fetchData", response);
					setData(() => response);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};

		fetchData();
	}, [user]);

	const renderMobileAppItem = (app: MobileAppModel) => (
		<>
			<ListItemText
				primary={
					<SCLabel
						color="#000"
						fontWeight="medium"
						align="left"
						textTransform="none"
					>
						<span className="text-lg font-bold">{app.name}</span>
						<Box component="span" sx={{ mx: 1 }}>
							<span className="text-sm font-semibold">
								({app.code})
							</span>
						</Box>
					</SCLabel>
				}
				secondary={
					<Box
						component="span"
						sx={{ display: "flex", alignItems: "center" }}
					>
						{/* iOS App Section */}
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								mr: 2,
							}}
						>
							<AppleIcon sx={{ color: "#000", mr: 1 }} />
							<SCLabel
								color="#000"
								fontWeight="medium"
								align="left"
								textTransform="none"
							>
								{app.iOSAppSettings?.bundleId || "No Bundle ID"}
							</SCLabel>
						</Box>

						{/* Android App Section */}
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<AndroidIcon sx={{ color: "green", mr: 1 }} />
							<SCLabel
								color="#000"
								fontWeight="medium"
								align="left"
								textTransform="none"
							>
								{app.androidAppSettings?.packageName ||
									"No Package Name"}
							</SCLabel>
						</Box>
					</Box>
				}
			/>
		</>
	);

	if (loading) {
		return (
			<LoadingPlaceHolder message="Loading your business data, please hold on!" />
		); // Pass the optional message
	}

	return (
		<EntityList<MobileAppModel>
			data={data}
			selectMode={selectMode} // or false depending on your requirements
			onSelect={(app) => handleSelectMobileApp(app)}
			renderChildren={renderMobileAppItem}
			onAddNewClick={onAddNewClick}
			helpText={[
				"Looks like you haven't registered any mobile apps for branding yet! You can start by adding one or more apps and link them to different businesses or phone numbers. Each app can include both iOS and Android versions",
				"Here's how it works: Once registered, Secure Calls will set up the necessary backend infrastructure, so when a call is made from your configured number, your brand will appear on your consumers' phones.",
				"Ready to get started? Simply click the 'Add New' button to register your apps for branding",
			]}
			listLabel={"Choose Your Mobile app"}
		/>
	);
};

export default MobileAppList;
