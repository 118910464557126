import { useState } from "react";

interface ErrorProps {
	error: string;
}

export const Error: React.FC<ErrorProps> = ({ error }) => {
	return (
		<>
			{error && (
				<span className="bg-red-600 text-white px-4 py-1 rounded-2xl font-semibold inline-flex items-center">
					{error}
				</span>
			)}
		</>
	);
};

