import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";
import { LoadingPlaceholderProps } from "./loading-placeholder.props";

const LoadingPlaceHolder: React.FC<LoadingPlaceholderProps> = ({ message }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
      {message && ( // Conditionally render the message if provided
        <Typography variant="h6" align="center" marginTop={2}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default LoadingPlaceHolder;
