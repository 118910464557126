import { ApiService } from "src/network/apiService";
import { BusinessNumberModel } from "src/types/models/apiModels";

export class BusinessNumbersListViewModel {
  private apiService: ApiService;

  constructor(accessToken: string) {
    this.apiService = new ApiService({ accessToken: accessToken });
  }

  async fetchList(businessId: string): Promise<BusinessNumberModel[]> {
    const response = await this.apiService.getAsync<any>(
      `/onboard/business/${businessId}/number/list`
    );

    if (response) {
      console.log(`/onboard/business/${businessId}/number/list`, response);
      if (response.scResponse && response.scResponse.code === 2000) {
        return response.scResponse.data;
      }

      return [];
    }

    return [];
  }
}
