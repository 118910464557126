import React, { useEffect, useRef, useState } from "react";
import {
	Avatar,
	Box,
	Button,
	Dialog,
	DialogContent,
	ListItemAvatar,
	ListItemText,
} from "@mui/material";

import { useAuth } from "../auth/auth-context";

import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import EntityList from "../entity-list/entity-list";
import { ApplicationListProps } from "./application-list.props";
import { ApplicationListViewModel } from "./application-list.view-model";
import { ApplicationModel } from "src/types/models/apiModels";
import ApplicationCredentials from "../application-credentials/application-credentials";

const ApplicationList: React.FC<ApplicationListProps> = ({
	onAddNewClick,
	selectMode,
	onSelect,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook
	const [data, setData] = useState<ApplicationModel[]>([]);
	const [loading, setLoading] = useState(true);
	const viewModel = useRef<ApplicationListViewModel | null>(null);
	const [selectedApplication, setSelectedApplication] = useState<
		string | null
	>(null);
	const [showApplicationCredentials, setShowApplicationCredentials] =
		useState(false);

	const handleSelectApplication = (application: ApplicationModel) => {
		setSelectedApplication(application.brandingApplicationId);
		if (onSelect) {
			onSelect(application);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new ApplicationListViewModel(
						user.accessToken
					);
					const response = await viewModel.current?.fetchList();
					console.log("fetchData ->", response);
					setData(() => response);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};

		fetchData();
	}, [user]);

	const renderBusinessItem = (application: ApplicationModel) => (
		<>
			<ListItemAvatar>
				<Avatar src={application.brandingApplicationId} />
			</ListItemAvatar>
			<ListItemText
				primary={
					<SCLabel
						color="#000"
						fontWeight="normal"
						align="left"
						textTransform="none"
					>
						{application.businessCode}
						{/* BusinessName - AppName -   */}
					</SCLabel>
				}
				secondary={
					<>
						<Box component="span" sx={{ mx: 1 }}>
							({application.contactCentreId})
						</Box>
					</>
				}
				primaryTypographyProps={{
					sx: {
						color:
							selectedApplication ===
							application.brandingApplicationId
								? "white"
								: "inherit",
						fontWeight:
							selectedApplication ===
							application.brandingApplicationId
								? "bold"
								: "normal",
					},
				}}
			/>

			<Button onClick={() => setShowApplicationCredentials(true)}>
				Show Credentials
			</Button>

			<Dialog
				open={showApplicationCredentials}
				onClose={() => setShowApplicationCredentials(false)}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<ApplicationCredentials
						brandingApplicationCode=""
						ccId=""
						isNewApp={false}
						onOkClick={() => {
							setShowApplicationCredentials(false);
						}}
					></ApplicationCredentials>
				</DialogContent>
			</Dialog>
		</>
	);
	if (loading) {
		return (
			<LoadingPlaceHolder message="Loading your business data, please hold on!" />
		); // Pass the optional message
	}

	return (
		<EntityList<ApplicationModel>
			data={data}
			selectMode={selectMode} // or false depending on your requirements
			onSelect={(selectedBusiness) =>
				handleSelectApplication(selectedBusiness)
			}
			renderChildren={renderBusinessItem}
			onAddNewClick={onAddNewClick}
			listLabel={"Choose Your Application"}
		/>
	);
};

export default ApplicationList;
