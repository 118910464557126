import React from "react";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ButtonActionProps } from "./button-action.props";
import "./button-action.css";

const ButtonAction: React.FC<ButtonActionProps> = ({ onClick, label }) => {
  return (
    <Button
      variant="text"
      onClick={onClick}
      startIcon={<AddCircleOutlineIcon />}
      className="action-button"
    >
      {label.toUpperCase()}
    </Button>
  );
};

export default ButtonAction;
