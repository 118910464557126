import React, { useEffect, useRef, useState } from "react";
import {
	Grid,
	RadioGroup,
	FormControlLabel,
	Radio,
	Box,
	FormGroup,
	Checkbox,
	TextField,
} from "@mui/material";

import ValidatedInput from "src/components/molecules/validated-input/validated-input";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import SectionHeader from "src/components/molecules/section-header/section-header";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import UploadFile from "../upload-file/upload-file";
import { NewMobileAppProps } from "./mobile-app-add-new.props";
import { NewMobileAppViewModel } from "./mobile-app-add-new.view-model";
import { useAuth } from "../auth/auth-context";
import ErrorPopup from "../error-popup/error-popup";
import HelpText from "src/components/atoms/help-text/help-text";
import {
	APNSCertificateType,
	IFileInfo,
	MobileAppModel,
} from "src/types/models/apiModels";
import PasswordRevealInput from "src/components/molecules/password-reveal-input/password-reveal-input";
import ButtonPro, {
	ButtonVariant,
} from "src/components/atoms/primary-button/primary-button";

const NewMobileApp: React.FC<NewMobileAppProps> = ({
	mobileApp,
	onCancel,
	onSave,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook

	type CertificateFileFormat = "P8" | "P12";
	const [showAPN, setShowAPN] = useState(false);
	const [showFCM, setShowFCM] = useState(false);
	const [iosCertFileFormat, setIOSCertFileFormat] =
		useState<CertificateFileFormat>("P8");
	const [iosCertFile, setIOSCertFile] = useState<IFileInfo | undefined>(
		undefined
	);
	const [androidCertFile, setAndroidCertFile] = useState<
		IFileInfo | undefined
	>(undefined);
	const [name, setName] = useState<string>("");
	const [bundleId, setBundleId] = useState<string>("");
	const [applePlatformKeyID, setApplePlatformKeyID] = useState<string>("");
	const [applePlatformTeamID, setApplePlatformTeamID] = useState<string>("");
	const [p12Password, setP12Password] = useState<string | undefined>(
		undefined
	);
	const [packageName, setPackageName] = useState<string>("");
	const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const [mode, setMode] = useState("New");
	const viewModel = useRef<NewMobileAppViewModel | null>(null);

	useEffect(() => {
		if (!mobileApp) return;

		setMode("Edit");
		setName(mobileApp.name);

		const { iOSAppSettings, androidAppSettings } = mobileApp;

		if (iOSAppSettings) {
			setShowAPN(true);
			setBundleId(iOSAppSettings.bundleId);

			const { p12FileSettings, p8FileSettings } = iOSAppSettings;

			if (p12FileSettings) {
				setIOSCertFileFormat(APNSCertificateType.P12);
				setP12Password(p12FileSettings.p12Password);
			} else if (p8FileSettings) {
				setIOSCertFileFormat(APNSCertificateType.P8);
				setApplePlatformKeyID(p8FileSettings.applePlatformKeyID);
				setApplePlatformTeamID(p8FileSettings.applePlatformTeamID);
			}
		}

		if (androidAppSettings) {
			setShowFCM(true);
			setPackageName(androidAppSettings.packageName);
		}
	}, [mobileApp]);

	useEffect(() => {
		if (user?.accessToken) {
			viewModel.current = new NewMobileAppViewModel(user.accessToken);
		}
	}, [user]);

	const onIOSCertUploadSuccess = (fileInfo: IFileInfo | undefined) => {
		if (fileInfo) {
			setIOSCertFile(fileInfo);
		}
	};

	const onAndroidCertUploadSuccess = (fileInfo: IFileInfo | undefined) => {
		if (fileInfo) {
			setAndroidCertFile(fileInfo);
		}
	};

	const onIOSCertClear = () => {
		setIOSCertFile(undefined);
	};

	const onAndroidCertClear = () => {
		setAndroidCertFile(undefined);
	};

	const toggleError = (value: boolean) => {
		console.log("In toggleError");
		setErrorDialogOpen((prevValue) => {
			if (prevValue !== value) {
				return value;
			}
			return prevValue;
		});
	};

	const closeError = () => {
		console.log("In closeError");
		toggleError(false);
	};

	const handleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const handleBackToList = () => {
		if (onCancel) {
			onCancel();
		}
	};

	async function handleSubmit(): Promise<void> {
		if (!name) {
			return;
		}

		const mobileApp: MobileAppModel = {
			id: "",
			code: "",
			name: name,
			iOSAppSettings:
				bundleId && iosCertFile
					? {
							bundleId: bundleId,
							certificateType:
								iosCertFileFormat === "P8"
									? APNSCertificateType.P8
									: APNSCertificateType.P12,
							certificateFile: iosCertFile,
							p8FileSettings:
								iosCertFileFormat === "P8"
									? {
											applePlatformTeamID:
												applePlatformTeamID ?? "",
											applePlatformKeyID:
												applePlatformKeyID ?? "",
											applePlatformBundleID:
												bundleId ?? "",
										}
									: undefined,
							p12FileSettings:
								iosCertFileFormat === "P12"
									? { p12Password: p12Password ?? "" }
									: undefined,
						}
					: undefined,
			androidAppSettings:
				packageName && androidCertFile
					? {
							packageName: packageName,
							certificateFile: androidCertFile,
						}
					: undefined,
			bundleId: bundleId,
			packageName: packageName,
		};

		if (mobileApp) {
			const errors =
				await viewModel.current?.validateMobileApp(mobileApp);
			if (errors && errors.length > 0) {
				setError(() => errors.join(","));
				toggleError(true);
			} else {
				const response =
					await viewModel.current?.saveMobileApp(mobileApp);
				if (response?.result === true) {
					if (onSave) {
						onSave(mobileApp);
					}
				} else {
					setError(() => response?.error ?? "");
					toggleError(true);
				}
			}
		}
	}
	return (
		<Box
			className="main-content"
			sx={{
				flexGrow: 1,
				padding: 3,
				marginLeft: `10px`,
				transition: "margin-left 0.3s ease",
			}}
		>
			<>
				{/* Help Text */}
				<HelpText
					help={[
						"You have the option to register an iOS app, an Android app, or both.",
						"To proceed, select the appropriate checkboxes below. Based on your selection, you will be prompted to provide the necessary details for Apple Push Notification (APN) or Firebase Cloud Messaging (FCM). This will ensure that your applications are properly configured for branding the calls",
					]}
					alignment="center"
				/>

				{/* Checkboxes to control section visibility */}
				<FormGroup row>
					<FormControlLabel
						control={
							<Checkbox
								checked={showAPN}
								onChange={(e) => setShowAPN(e.target.checked)}
							/>
						}
						label="iOS App"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={showFCM}
								onChange={(e) => setShowFCM(e.target.checked)}
							/>
						}
						label="Android App"
					/>
				</FormGroup>
				<Grid container spacing={2} marginTop={2}>
					<Grid item xs={12} sm={12}>
						<ValidatedInput
							label="Name"
							value={name}
							type="RequiredString"
							onChange={setName}
							maxLength={32}
							disabled={mode === "Edit"}
						/>
					</Grid>
					{/* APN Section */}
					{showAPN && (
						<Grid item xs={12} md={6}>
							<SectionHeader title="Apple Push Notification (APN)" />
							<SCLabel color="#000" align="left">
								Choose Authentication Format
							</SCLabel>

							<RadioGroup
								row
								value={iosCertFileFormat}
								onChange={(e) =>
									setIOSCertFileFormat(
										e.target.value as CertificateFileFormat
									)
								}
							>
								<FormControlLabel
									value="P8"
									control={<Radio />}
									label="P8"
								/>
								<FormControlLabel
									value="P12"
									control={<Radio />}
									label="P12"
								/>
							</RadioGroup>

							<Box
								className="browse-container"
								sx={{ marginTop: 2 }}
							>
								<UploadFile
									label="Upload credential file"
									onUploadSuccess={onIOSCertUploadSuccess}
									onClear={onIOSCertClear}
								/>
							</Box>

							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: 3,
								}}
							>
								<ValidatedInput
									label="Bundle ID"
									value={bundleId}
									type={"RequiredString"}
									onChange={setBundleId}
									disabled={mode === "Edit"}
								/>

								{iosCertFileFormat === "P8" && (
									<>
										<ValidatedInput
											label="Key ID"
											value={applePlatformKeyID}
											type={"RequiredString"}
											onChange={setApplePlatformKeyID}
										/>
										<ValidatedInput
											label="Team ID"
											value={applePlatformTeamID}
											type={"RequiredString"}
											onChange={setApplePlatformTeamID}
										/>
									</>
								)}
								{iosCertFileFormat === "P12" && (
									<PasswordRevealInput
										label="Password (optional)"
										value={p12Password}
										onChange={(e) => {
											setP12Password(e);
										}}
									/>
									// <ValidatedInput
									//   label="Password"
									//   value={p12Password}
									//   type={"RequiredString"}
									//   onChange={setP12Password}
									// />
								)}
							</Box>
						</Grid>
					)}

					{/* FCM Section */}
					{showFCM && (
						<Grid item xs={12} md={6}>
							<SectionHeader title="Firebase Cloud Messaging (FCM)" />
							<Box
								className="browse-container"
								sx={{ marginTop: 2 }}
							>
								<UploadFile
									label="Upload Service account key"
									onUploadSuccess={onAndroidCertUploadSuccess}
									onClear={onAndroidCertClear}
								/>
							</Box>
							<ValidatedInput
								label="Package Name"
								value={packageName}
								type={"RequiredString"}
								onChange={setPackageName}
								disabled={mode === "Edit"}
							/>
						</Grid>
					)}
				</Grid>
				<Box sx={{ marginTop: 2 }}>
					<ErrorPopup
						message={error}
						open={errorDialogOpen}
						onClose={closeError}
					></ErrorPopup>
					{(showAPN || showFCM) && (
						<div className="flex flex-col items-center justify-center gap-3">
							<ButtonPro label="Submit" onClick={handleSubmit} />
							<ButtonPro
								label="Back"
								variant={ButtonVariant.Secondary}
								onClick={handleBackToList}
							/>
						</div>
					)}
				</Box>
			</>
		</Box>
	);
};

export default NewMobileApp;
