import React from "react";

export enum ButtonVariant {
	Primary,
	Secondary,
}

interface ButtonProProps {
	type?: "submit" | "reset" | "button"; // Restrict to specific string values and allow undefined
	label: string;
	variant?: ButtonVariant;
	onClick?: () => void;
	disabled?: boolean;
}

const ButtonPro: React.FC<ButtonProProps> = ({
	type,
	label,
	variant = ButtonVariant.Primary,
	onClick,
	disabled = false, // Set default value for disabled
}) => {
	// Determine the styles based on the variant and disabled state
	let buttonStyle = "";
	switch (variant) {
		case ButtonVariant.Primary:
			buttonStyle = "from-sky-600 to-sky-900";
			break;
		case ButtonVariant.Secondary:
			buttonStyle = "from-slate-600 to-slate-900";
			break;
		default:
			buttonStyle = "from-sky-600 to-sky-900";
	}

	return (
		<button
			type={type}
			onClick={disabled ? undefined : onClick} // Prevent onClick if disabled
			className={`bg-gradient-to-br w-full ${buttonStyle} text-white py-3  transition-all duration-150 rounded-3xl shadow-[5px_5px_10px_rgba(0,0,0,.5)] ${
				disabled
					? "opacity-50 cursor-not-allowed" // Disabled styles
					: "active:transform active:translate-y-1"
			}`}
			disabled={disabled} // Set the disabled attribute
		>
			<span className="uppercase font-semibold tracking-wider">
				{label}
			</span>
		</button>
	);
};

export default ButtonPro;
