import { BannerProps } from "./Banner.props";

const Banner: React.FC<BannerProps> = (props) => {
	return (
		<div>
			<span
				className="bg-gradient-to-r from-sky-700 to-sky-950 text-white rounded-t-lg
			 min-h-24 flex items-center justify-center uppercase drop-shadow-lg text-4xl tracking-wider font-semibold border-b-8 border-white"
			>
				{props.label}
			</span>
			<div className="bg-slate-200 p-8 rounded-b-lg drop-shadow-2xl">
				{props.children}
			</div>
		</div>
	);
};

export default Banner;
