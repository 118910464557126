import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import CallAlert from "src/components/molecules/callAlerts/callAlert";
import PhoneComponent from "src/components/molecules/phoneComponent/phoneComponent";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import AndroidPhoneComponent from "src/components/molecules/phoneComponent/androidPhone";

import { BrandingConfigurationProps } from "./branding-configuration.props";

import { BrandingConfigurationViewmodel } from "./branding-configuration.view-model";
import { useAuth } from "../auth/auth-context";
import HelpText from "src/components/atoms/help-text/help-text";
import UploadButton from "src/components/molecules/upload-button/upload-button";
import {
	AlertContent,
	IFileInfo,
	BusinessNumberModel,
} from "src/types/models/apiModels";

const BrandingConfiguration: React.FC<BrandingConfigurationProps> = ({
	business,
	number,
	scProvidedNumber,
	editNumber,
	onChange,
}) => {
	const defaultImage = "https://via.placeholder.com/150";
	const defaultAlertContent = {
		title: "",
		body: "",
	};
	const { user } = useAuth(); // Extract user from useAuth hook
	const [loading, setLoading] = useState(true);
	const viewModel = useRef<BrandingConfigurationViewmodel | null>(null);

	const [selectedImage, setSelectedImage] = useState<string>(
		editNumber?.imageFile?.signedUrl ?? defaultImage
	);
	const [selectedImageFile, setSelectedImageFile] = useState<IFileInfo>();
	const [setupAlertContent, setSetupAlertContent] = useState<AlertContent>(
		editNumber?.setupPushContent
			? editNumber.setupPushContent
			: defaultAlertContent
	);
	const [cleanupAlertContent, setCleanupAlertContent] =
		useState<AlertContent>(
			editNumber?.cleanupPushContent
				? editNumber.cleanupPushContent
				: defaultAlertContent
		);
	const [defaultIntent, setDefaultIntent] = useState("");
	const [brandingName, setBrandingName] = useState("");
	const [businessNumber, setBusinessNumber] = useState<
		BusinessNumberModel | undefined
	>();

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new BrandingConfigurationViewmodel(
						user.accessToken
					);
					const response = await viewModel.current?.fetchNumber(
						business.businessId,
						number
					);
					console.log("fetchData", response);

					if (response) {
						const defaultPic = response.imageFile
							? response.imageFile
							: business.logo;
						const num: BusinessNumberModel = {
							...response,

							imageFile: defaultPic,
							brandingName:
								response.brandingName ?? business.name,
							defaultIntent:
								response.defaultIntent ?? "Default intent",
							setupPushContent: response.setupPushContent ?? {
								title: business.name,
								body: "Incoming call scheduled...",
							},
							cleanupPushContent: response.cleanupPushContent ?? {
								title: business.name,
								body: "Branding cleared",
							},
							scProvidedNumber: scProvidedNumber,
						};

						setBusinessNumber(() => num);
						if (num.setupPushContent) {
							setSetupAlertContent(
								() =>
									num.setupPushContent ?? defaultAlertContent
							);
						}
						if (num.cleanupPushContent) {
							setCleanupAlertContent(
								() =>
									num.cleanupPushContent ??
									defaultAlertContent
							);
						}

						setSelectedImage(
							() => num.imageFile?.signedUrl ?? defaultImage
						);
						setBrandingName(() => num.brandingName ?? "");
						setDefaultIntent(() => num.defaultIntent ?? "");
					}
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};

		fetchData();
	}, [user]);

	useEffect(() => {
		if (onChange && businessNumber) {
			onChange(businessNumber);
		}
	}, [businessNumber]);

	useEffect(() => {
		console.log("^^^^defaultIntent", defaultIntent);
		if (businessNumber && selectedImageFile) {
			const updatedNumber: BusinessNumberModel = {
				...businessNumber,
				imageFile: selectedImageFile,
			};
			updatedNumber.setupPushContent = setupAlertContent;
			updatedNumber.cleanupPushContent = cleanupAlertContent;
			updatedNumber.defaultIntent = defaultIntent;
			updatedNumber.brandingName = brandingName;
			updatedNumber.imageFile = selectedImageFile;
			setBusinessNumber(() => updatedNumber);
		}
	}, [
		setupAlertContent,
		cleanupAlertContent,
		defaultIntent,
		brandingName,
		selectedImageFile,
	]);

	const handleSetupAlertChange = (key: keyof AlertContent, value: string) => {
		setSetupAlertContent((prevAlert: AlertContent) => ({
			...prevAlert,
			[key]: value,
		}));
	};
	const handleCleanupAlertChange = (
		key: keyof AlertContent,
		value: string
	) => {
		setCleanupAlertContent((prevAlert: AlertContent) => ({
			...prevAlert,
			[key]: value,
		}));
	};
	const handleImageUpload = async (image: IFileInfo | undefined) => {
		console.log("In handleImageUpload!", image);
		if (image) {
			setSelectedImageFile(() => image);
		}

		setSelectedImage(image?.signedUrl ?? defaultImage);
	};

	const handleSubmit = async () => {
		// Handle form submission logic here
	};

	function handleBusinessNumberChange(value: string): void {
		//handleFieldChange("setupPushContent", value);
	}

	return (
		<>
			<HelpText
				help={[
					`You can configure your branding screens and alerts for ${number} here`,
					"To update the logo, simply click on any of the logos in the images, it will automatically apply to both the call screen and alerts",
					"The text fields in the images are editable—just click on the text to begin editing. Alternatively, you can use the form below to update the information",
					`Once you've finished editing the screen, complete the other settings, and you're ready to save your branding for ${number}`,
				]}
			></HelpText>
			<Grid container direction="row" spacing={2}>
				<Grid item xs={8} md={9} lg={8}>
					<Grid
						container
						spacing={2}
						justifyContent="center"
						alignItems="center"
						direction="row"
						sx={{ height: "100%" }}
					>
						<Grid item xs={5}>
							<Box sx={{ marginBottom: "8px" }}>
								<CallAlert
									headerLabel="Incoming call alert"
									alertContent={setupAlertContent}
									image={selectedImage}
									onChange={handleSetupAlertChange}
								/>
							</Box>

							<PhoneComponent
								image={selectedImage}
								title={brandingName ?? ""}
								subtitle={defaultIntent ?? ""}
							/>
							<SCLabel color="#ff344">iPhone</SCLabel>
						</Grid>

						<Grid item xs={5} gap={1}>
							<Box sx={{ marginBottom: "8px" }}>
								<CallAlert
									headerLabel="Branding clear Alert"
									alertContent={cleanupAlertContent}
									image={selectedImage}
									onChange={handleCleanupAlertChange}
								/>
							</Box>
							<AndroidPhoneComponent
								image={selectedImage}
								title={brandingName ?? ""}
								subtitle={defaultIntent ?? ""}
								borderRadius="24px"
							/>
							<SCLabel color="#ff344">Android</SCLabel>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={4}
					md={3}
					lg={4}
					container
					direction="column"
					justifyContent="space-between" // Space the items equally
					alignItems="center"
					spacing={2}
					style={{ height: "100%" }} // Ensure the height is 100%
				>
					<Grid
						item
						xs={12}
						style={{ width: "100%", alignItems: "start" }}
					>
						<UploadButton
							defaultImage={selectedImage}
							onUploadSuccess={handleImageUpload}
							borderRadius="50%"
							buttonType="Image"
						/>
						<Typography>Click on the image to update it</Typography>
						<SCLabel
							align="left"
							color="#000"
							fontWeight="bold"
							marginTop={3}
						>
							Incoming call alert
						</SCLabel>
						<TextField
							variant="filled"
							label="Title"
							fullWidth
							required
							value={setupAlertContent?.title}
							onChange={(e) =>
								handleSetupAlertChange("title", e.target.value)
							}
						/>
						<TextField
							variant="filled"
							label="Body"
							fullWidth
							required
							value={setupAlertContent?.body}
							onChange={(e) =>
								handleSetupAlertChange("body", e.target.value)
							}
						/>
					</Grid>
					<Grid item xs={12} style={{ width: "100%" }}>
						<SCLabel align="left" color="#000" fontWeight="bold">
							Branding clear alert
						</SCLabel>

						<TextField
							variant="filled"
							label="Title"
							fullWidth
							required
							value={cleanupAlertContent?.title}
							onChange={(e) =>
								handleCleanupAlertChange(
									"title",
									e.target.value
								)
							}
						/>
						<TextField
							variant="filled"
							label="Body"
							fullWidth
							required
							value={cleanupAlertContent?.body}
							onChange={(e) =>
								handleCleanupAlertChange("body", e.target.value)
							}
						/>
					</Grid>
					<Grid item xs={12} style={{ width: "100%" }}>
						<SCLabel align="left" color="#000" fontWeight="bold">
							Call screen
						</SCLabel>

						<TextField
							variant="filled"
							label="Branding name"
							fullWidth
							required
							value={brandingName}
							onChange={(e) => setBrandingName(e.target.value)}
						/>
						<TextField
							variant="filled"
							label="Default intent"
							fullWidth
							required
							value={defaultIntent}
							onChange={(e) => setDefaultIntent(e.target.value)}
						/>
					</Grid>

					{/* <Grid item xs={12} style={{ width: "100%" }}>
            <PrimaryButton
              variant="outlined"
              color="info"
              label="Save Branding"
              cssClassName="primary-button"
              onClick={handleSubmit}
              disabled={false}
            />
          </Grid> */}
				</Grid>
				<Grid
					item
					xs={4}
					md={3}
					lg={4}
					container
					direction="column"
					justifyContent="space-between" // Space the items equally
					alignItems="center"
					spacing={2}
					style={{ height: "100%" }} // Ensure the height is 100%
				></Grid>
			</Grid>
		</>
	);
};

export default BrandingConfiguration;
