import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { BrandingOptionProps } from "./branding-option.props";

const BrandingOption: React.FC<BrandingOptionProps> = ({ value, onChange }) => {
  const handleChange = (event: any) => {
    onChange(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="number-type"
        name="numberType"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value="CPaaSManagedCallBranding"
          control={<Radio />}
          label="Branding with CPaaS Numbers/Cloud Numbers"
        />
        <Typography variant="body2" color="textSecondary" sx={{ ml: 4 }}>
          Cloud-based numbers that offer advanced communication features like
          programmable voice through APIs.
        </Typography>

        <FormControlLabel
          value="UnmanagedCallBranding"
          control={<Radio />}
          label="Branding with Traditional Telephony"
        />
        <Typography variant="body2" color="textSecondary" sx={{ ml: 4 }}>
          Standard phone numbers connected to the public switched telephone
          network (PSTN) for regular voice calls.
        </Typography>
      </RadioGroup>
    </FormControl>
  );
};

export default BrandingOption;
