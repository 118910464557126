import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import Banner from "src/components/molecules/banner/Banner";
import LoadingModal from "src/components/molecules/loading/LoadingModal";
import { confirmationCode } from "../auth";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import ButtonPro from "src/components/atoms/primary-button/primary-button";
import InputBox from "src/components/atoms/v2/inputBox/inputBox";
import Logo from "src/components/molecules/logo/Logo";

const ConfirmPassword: React.FC = () => {
	const location = useLocation();
	const email = location.state?.businessEmail;
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [authCode, setAuthCode] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");

	const navigate = useNavigate();

	useEffect(() => {
		if (!email) {
			navigate("/login");
		}
	}, [email, navigate]);

	const handleVerifyCodeSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		console.log(`in..`);
		setError("");
		// Ensure passwords match before proceeding
		if (newPassword !== confirmNewPassword) {
			console.log(`Passwords do not match!`);
			setError("Passwords do not match!");
			return;
		}
		setLoading(true);
		setError(""); // Clear error if passwords match
		console.log("Auth Code Submitted:", authCode);
		confirmationCode(email, authCode, newPassword, (err) => {
			setLoading(false);
			if (err) {
				console.error(`error ${err.message}`);
				setError(err.message || JSON.stringify(err));
			} else {
				navigate("/login");
			}
		});
	};

	return (
		<>
			<div className="flex flex-col items-center justify-start md:justify-center h-screen w-screen">
				<div className="bg-white text-gray-900 flex flex-col gap-6 items-center justify-start md:justify-center h-fit w-[50vh] p-12 rounded-2xl md:shadow-[0_30px_50px_rgba(0,0,0,.5)]">
					<span className="text-4xl font-bold font-Poppins">
						Reset your password?
					</span>
					<Logo></Logo>
					<label className="ms-2 text-md font-semibold">
						Don&apos;t have a secured calls account?{" "}
						<a
							href="/signup"
							className="text-blue-600 dark:text-blue-500 hover:underline"
						>
							Sign-up here.
						</a>
					</label>
					<form
						className="flex flex-col items-center justify-center gap-6 w-full"
						onSubmit={handleVerifyCodeSubmit}
					>
						<InputBox
							value={authCode}
							label={"Authorisation code"}
							type={"text"}
							onChange={function (value: string): void {
								setAuthCode(value);
							}}
						></InputBox>
						<InputBox
							value={newPassword}
							label={"New password"}
							type={"password"}
							onChange={function (value: string): void {
								setNewPassword(value);
							}}
						></InputBox>
						<InputBox
							value={confirmNewPassword}
							label={"Confirm new password"}
							type={"password"}
							onChange={function (value: string): void {
								setConfirmNewPassword(value);
							}}
						></InputBox>
						<span className="text-red-500 font-semibold">
							{error}
						</span>
						<ButtonPro
							label={"UPDATE PASSWORD"}
							type="submit"
						></ButtonPro>
					</form>
					<label className="ms-2 text-md font-semibold text-white md:text-gray-900">
						<a
							href="/login"
							className="text-blue-600 dark:text-blue-500 hover:underline flex items-center"
						>
							<ArrowLeft className="mr-2" />{" "}
							{/* Add margin to separate arrow from text */}
							Back to login
						</a>
					</label>
					<LoadingModal open={loading} message="Please wait..." />
				</div>
			</div>
		</>
	);
};

export default ConfirmPassword;
