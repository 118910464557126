import { useState } from "react";
import { Box, List } from "@mui/material";
import { EntityListProps } from "./entity-list.props";

import ButtonAddNew from "src/components/atoms/button-add-new/button-add-new";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import SelectableListItem from "src/components/molecules/selectable-list-item/selectable-list-item";

import "./entity-list.css";
import HelpText from "src/components/atoms/help-text/help-text";
import SCLabel from "src/components/atoms/sc-label/sc-label";

const EntityList = <T,>({
  data,
  selectMode,
  helpText,
  listLabel,
  onAddNewClick,
  onSelect,
  renderChildren,
  onClose,
}: EntityListProps<T>) => {
  const [selectedEntity, setSelectedEntity] = useState<T | null>(null);

  const handleSelectItem = (item: T) => {
    if (selectMode) {
      setSelectedEntity(item);
    }
    if (onSelect) {
      // console.log(`on select in EntityList ${JSON.stringify(item)}`);
      onSelect(item);
    }
  };

  const handleClose = () => {
    if (onClose) {
      if (onSelect && selectedEntity) {
        onSelect(selectedEntity);
      }
      onClose();
    }
  };

  return (
    <>
      {selectMode && (
        <SCLabel color="#000" align="left" paddingLeft={4} paddingTop={1}>
          {listLabel}
        </SCLabel>
      )}
      <Box
        className="main-content"
        sx={{
          flexGrow: 1,
          padding: 3,
          marginLeft: `10px`,
          transition: "margin-left 0.3s ease",
        }}
      >
        <Box>
          <List sx={{ width: "100%", bgcolor: "transparent" }}>
            {data.map((item, index) => (
              <SelectableListItem
                key={index} // Adjust the key as needed based on T's properties
                item={item}
                selectMode={selectMode ?? false}
                selected={selectedEntity === item}
                onSelect={() => handleSelectItem(item)}
              >
                {renderChildren(item)}
              </SelectableListItem>
            ))}
          </List>
          {data.length === 0 && helpText && helpText.length > 0 && (
            <HelpText help={helpText} alignment="center" />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            {!selectMode && (
              <ButtonAddNew onClick={onAddNewClick}></ButtonAddNew>
            )}
            {selectMode && (
              <PrimaryButton
                type="submit"
                variant="outlined"
                color="info"
                label="OK"
                cssClassName="primary-button"
                disabled={false}
                onClick={handleClose}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EntityList;
