import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../auth/auth-context";
import { Box } from "@mui/material";
import { BusinessAddNewProps } from "./business-add-new.props";
import { NewBusinessViewModel } from "./business-add-new.view-model";
import BusinessContact from "../business-contact/business-contact";
import BusinessDetails from "../business-details/business-details";
import BusinessInfo from "../business-info/business-info";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import NewBusinessNumbers from "../business-numbers/business-numbers";
import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import ErrorPopup from "../error-popup/error-popup";
import ConfirmationPopup from "../confirmation-popup/confirmation-popup";

import {
	BusinessDetailsModel,
	BusinessInfoModel,
	BusinessModel,
	ContactModel,
	NewNumberModel,
} from "src/types/models/apiModels";
import ButtonPro, {
	ButtonVariant,
} from "src/components/atoms/primary-button/primary-button";

const BusinessAddNew: React.FC<BusinessAddNewProps> = ({
	onSubmitClick,
	onCancelClick,
	editBusinessModel,
}) => {
	const { user } = useAuth(); // Extract user from useAuth hook
	const [loading, setLoading] = useState(false);
	const [business, setBusiness] = useState<BusinessModel | undefined>(
		editBusinessModel
	);
	const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
	const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const viewModel = useRef<NewBusinessViewModel | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			setLoading((prevValue) => {
				if (!prevValue) {
					return true;
				}
				return prevValue;
			});
			try {
				if (user && user.accessToken) {
					viewModel.current = new NewBusinessViewModel(
						user.accessToken
					);
					const response =
						await viewModel.current?.initBusiness(business);
					console.log("initBusiness", response);
					setBusiness(() => response);
				}
			} catch (error) {
				console.error("Error fetching initBusiness:", error);
			} finally {
				setLoading((prevValue) => {
					if (prevValue) {
						return false;
					}
					return prevValue;
				});
			}
		};

		fetchData();
	}, [user]);

	const handleInfoChange = (info: BusinessInfoModel) => {
		if (business) {
			business.info = info;
			setBusiness(() => business);
		}
	};
	const handleContactChange = (contact: ContactModel) => {
		if (business) {
			business.primaryContact = contact;
			setBusiness(() => business);
		}
	};
	const handleDetailsChange = (details: BusinessDetailsModel) => {
		if (business) {
			business.details = details;
			setBusiness(() => business);
		}
	};
	const handleNumbersChange = (numbers: NewNumberModel[]) => {
		if (business) {
			business.numbers = numbers;
			setBusiness(() => business);
		}
	};
	const handleSubmitBusiness = async () => {
		console.log("In handleSubmitBusiness", business);
		setBusiness(() => business);
		if (business) {
			const validate =
				await viewModel.current?.validateBusiness(business);
			if (validate && validate.length > 0) {
				setError(() => validate.join(","));
				toggleError(true);
			} else {
				setConfirmDialogOpen((prevValue) => {
					if (prevValue === false) {
						return true;
					}
					return prevValue;
				});
			}
		}
	};
	const handleConfirmSubmitBusiness = async () => {
		console.log("In confirmSubmitBusinessCallBack", business);
		if (business) {
			const response = await viewModel.current?.saveBusiness(business);
			console.log("response", response);
			if (response?.result === true) {
				onSubmitClick(business);
			} else {
				setError(() => response?.error ?? "");
				toggleError(true);
			}
		}
	};
	const toggleError = (value: boolean) => {
		console.log("In toggleError");
		setErrorDialogOpen((prevValue) => {
			if (prevValue !== value) {
				return value;
			}
			return prevValue;
		});
	};
	const closeError = () => {
		console.log("In closeError");
		toggleError(false);
	};
	const closeConfirm = () => {
		setConfirmDialogOpen((prevValue) => {
			if (prevValue === true) {
				return false;
			}
			return prevValue;
		});
	};
	const handleCancel = () => {
		console.log("In handleCancel");

		onCancelClick();
	};

	if (loading) {
		return (
			<LoadingPlaceHolder message="We're setting things up for you. Just a moment!" />
		);
	}
	return (
		<div className="bg-white p-12 rounded-lg drop-shadow-2xl">
			<BusinessContact
				editContact={business?.primaryContact}
				onChange={handleContactChange}
				useLoggedInUser={true}
			></BusinessContact>

			<BusinessInfo
				info={business?.info}
				onChange={handleInfoChange}
			></BusinessInfo>

			<BusinessDetails
				businessDetails={business?.details}
				onChange={handleDetailsChange}
			></BusinessDetails>

			<NewBusinessNumbers
				editNumbers={business?.numbers}
				onChange={handleNumbersChange}
			></NewBusinessNumbers>

			<ErrorPopup
				message={error}
				open={errorDialogOpen}
				onClose={closeError}
			></ErrorPopup>
			<ConfirmationPopup
				business={business}
				onConfirm={handleConfirmSubmitBusiness}
				open={confirmDialogOpen}
				onClose={closeConfirm}
			></ConfirmationPopup>
			<div className="flex flex-col gap-2">
				<ButtonPro
					label={"Review"}
					onClick={handleSubmitBusiness}
				></ButtonPro>
				<ButtonPro
					variant={ButtonVariant.Secondary}
					label={"Cancel"}
					onClick={handleCancel}
				></ButtonPro>
			</div>
		</div>
	);
};

export default BusinessAddNew;
