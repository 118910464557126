import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";

import Banner from "src/components/molecules/banner/Banner";

import { ApplicationProps } from "./application.props";
import "./application.css";
import ApplicationAddNew from "../application-add-new/application-add-new";
import { ApplicationModel } from "src/types/models/apiModels";
import ApplicationList from "../application-list/application-list";

const Application: React.FC<ApplicationProps> = ({ openInEditMode }) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [title, setTitle] = useState("");

  const handleAddApplication = useCallback(() => {
    console.log("addBusinessCallBack!");
    toggleEditMode();
  }, []); // No dependencies, so this callback won't change across renders

  const handleSubmitApplication = useCallback(
    (application: ApplicationModel) => {
      console.log("addBusinessCallBack!", application);
      toggleEditMode();
    },
    []
  );

  const handleOnAdd = useCallback(() => {
    // console.log("cancelBusinessCallBack!");
    toggleEditMode();
  }, []);

  const handleCancelApplication = useCallback(() => {
    console.log("cancelBusinessCallBack!");
    toggleEditMode();
  }, []);

  const toggleEditMode = () => {
    console.log("In toggleEditMode");
    setEditMode((prevValue) => !prevValue);
  };

  useEffect(() => {
    if (!editMode) {
      setTitle("Branding application list");
    } else {
      setTitle("Branding application");
    }
  }, [editMode]);

  // Functions to handle opening and closing the dialog

  return (
    <Box
      className="main-content"
      sx={{
        flexGrow: 1,
        padding: 3,
        marginLeft: `10px`,
        transition: "margin-left 0.3s ease",
      }}
    >
      <Banner label={title}>
        {editMode === false ? (
          <ApplicationList
            onAddNewClick={handleOnAdd}
            onSelect={() => {
              console.log("2");
            }}
          ></ApplicationList>
        ) : (
          <ApplicationAddNew
            onSubmitClick={handleSubmitApplication}
            onCancelClick={handleCancelApplication}
          ></ApplicationAddNew>
        )}
      </Banner>
    </Box>
  );
};

export default Application;
