import React from "react";
import { SelectableListItemProps } from "./selectable-list-item.props";
import { Box, IconButton, ListItem } from "@mui/material";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/edit.svg";

const SelectableListItem: React.FC<SelectableListItemProps> = ({
	selected,
	item,
	onSelect,
	selectMode,
	children,
}) => {
	const onEditClicked = () => {
		if (onSelect) {
			onSelect(item);
		}
	};

	return (
		<ListItem
			sx={{
				backgroundColor: selected ? "#BFE3FF" : "transparent",
				border: selected
					? "2px solid gray" // Thicker, highlighted border when selected
					: "1px solid #ddd", // Default border
				borderRadius: 2,
				marginBottom: 1, // Adds space between the cards
				padding: 1, // Adds padding inside the card
				display: "flex",
				alignItems: "center",
				transition: "background-color 0.3s ease", // Smooth transition on hover
				"&:hover": {
					backgroundColor: selected ? "#b0bec5" : "#f5f5f5", // Lighter background on hover
				},
			}}
			onClick={selectMode ? onEditClicked : undefined}
		>
			{children}

			{!selectMode && (
				<>
					<Box sx={{ display: "flex", gap: 1 }}>
						<IconButton
							size="small"
							color="inherit"
							onClick={onEditClicked}
						>
							<EditIcon />
						</IconButton>
						<IconButton
							disabled={true}
							size="small"
							color="inherit"
						>
							<DeleteIcon />
						</IconButton>
					</Box>
				</>
			)}
		</ListItem>
	);
};

export default SelectableListItem;
