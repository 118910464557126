import { Grid, Box, Link } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import WebIcon from "@mui/icons-material/Web";
import SCLabel from "src/components/atoms/sc-label/sc-label";
import PrimaryButton from "src/components/atoms/button/Primary-Button";
import PasswordRevealInput from "src/components/molecules/password-reveal-input/password-reveal-input";
import { useEffect, useRef, useState } from "react";
import LoadingPlaceHolder from "src/components/molecules/loading/loading-placeholder";
import { ApplicationCredentialsViewModel } from "./application-credentials-viewmodel";
import { useAuth } from "../auth/auth-context";

interface IApplicationCredentialsProps {
	brandingApplicationCode: string;
	ccId?: string;
	isNewApp: boolean;
	onOkClick?: () => void;
}

const ApplicationCredentials: React.FC<IApplicationCredentialsProps> = ({
	brandingApplicationCode = "",
	ccId = "",
	isNewApp = false,
	onOkClick,
}) => {
	const [loadingDeviceData, setLoadingDeviceData] = useState(false);
	const [loadingCCData, setLoadingCCData] = useState(false);
	const viewModel = useRef<ApplicationCredentialsViewModel | null>(null);
	const { user } = useAuth();
	const [
		iOSDeviceCredentialsDownloadUrl,
		setiOSDeviceCredentialsDownloadUrl,
	] = useState("");
	const [
		androidDeviceCredentialsDownloadUrl,
		setAndroidDeviceCredentialsDownloadUrl,
	] = useState("");
	const [secretDeviceCredentials, setSecretDeviceCredentials] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			console.log(`Downloading the data for devices`);

			setLoadingDeviceData(true);
			setLoadingCCData(true);

			if (user && user.accessToken) {
				viewModel.current = new ApplicationCredentialsViewModel(
					user.accessToken
				);
			}

			const deviceCredentials =
				await viewModel.current?.fetchDeviceCredentials(
					brandingApplicationCode
				);

			setLoadingDeviceData(false);

			if (deviceCredentials) {
				setiOSDeviceCredentialsDownloadUrl(
					deviceCredentials.iosFileUrl
				);
				setAndroidDeviceCredentialsDownloadUrl(
					deviceCredentials.androidFileUrl
				);
				setSecretDeviceCredentials(deviceCredentials.secret);
			}

			if (ccId) {
				setLoadingCCData(true);
				console.log(`Downloading the data for Contact centre`);
			}
			console.log(`downloaded data for the application.`);

			setLoadingCCData(false);
		};

		fetchData();
	}, []);

	return (
		<>
			{!loadingDeviceData && !loadingCCData && (
				<Grid
					container
					direction="column"
					alignItems="center"
					spacing={4}
				>
					{isNewApp && (
						<Grid item>
							<span
								style={{
									backgroundColor: "#f0f0f0", // Background color
									padding: "6px", // Padding of 12px
									display: "inline-block", // Ensures the padding is applied correctly to the span
								}}
							>
								<SCLabel
									color="#003060"
									textTransform="none"
									fontSize={18}
								>
									Your new application is ready to be
									integrated. Please download the necessary
									SDK(s) and credentials to get started.
								</SCLabel>
							</span>
						</Grid>
					)}

					<Grid item>
						<SCLabel
							color="#003060"
							textTransform="none"
							fontSize={20}
						>
							How to download and integrate Secured Calls SDK(s)
						</SCLabel>
					</Grid>

					{/* Apple SDK */}
					<Grid
						item
						container
						direction="column"
						alignItems="center"
						spacing={2}
					>
						<Grid item>
							<AppleIcon sx={{ fontSize: 80 }} />{" "}
							{/* Double the size */}
						</Grid>
						<Grid item>
							<Box display="flex" alignItems="center" gap={1}>
								<Link
									href="https://github.com/expertstack-studios/ios-securevoicecall-sdk/blob/main/README.md"
									target="_blank"
									rel="noopener noreferrer"
									color="primary"
									underline="always"
								>
									iOS SDK Documentation
								</Link>
								<span>|</span>
								<Link
									href="https://www.youtube.com/watch?v=ts0K1YDxQMY"
									target="_blank"
									rel="noopener noreferrer"
									color="primary"
									underline="always"
								>
									iOS SDK Integration Video
								</Link>
							</Box>
						</Grid>
						<Grid item>
							<Link
								href="/path/to/ios-secret.dat"
								download="ios-secret.dat"
								color="primary"
								underline="always"
							>
								Download iOS Secret (.dat)
							</Link>
						</Grid>
					</Grid>

					{/* Android SDK */}
					<Grid
						item
						container
						direction="column"
						alignItems="center"
						spacing={2}
					>
						<Grid item>
							<AndroidIcon sx={{ fontSize: 80 }} />{" "}
							{/* Double the size */}
						</Grid>
						<Grid item>
							<Box display="flex" alignItems="center" gap={1}>
								<Link
									href="https://github.com/expertstack-studios/sc-android-voicecall-sample/blob/main/README.md"
									target="_blank"
									rel="noopener noreferrer"
									color="primary"
									underline="always"
								>
									Android SDK Documentation
								</Link>
								{/* <span>|</span>
                <Link
                  href="https://www.youtube.com/watch?v=ts0K1YDxQMY"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                  underline="always"
                >
                  Android SDK Integration Video
                </Link> */}
							</Box>
						</Grid>
						<Grid item>
							<Link
								href="/path/to/android-secret.dat"
								download="android-secret.dat"
								color="primary"
								underline="always"
							>
								Download Android Secret (.dat)
							</Link>
						</Grid>
					</Grid>
					<Grid item sx={{ width: "100%" }}>
						{/* Full width */}
						<SCLabel color="#003060">Mobile app Secret</SCLabel>
						<PasswordRevealInput
							value={"Your Web Secret Key"}
							onChange={(value: string) => {
								console.log(value);
							}}
						/>
					</Grid>

					{/* Web SDK */}
					<Grid
						item
						container
						direction="column"
						alignItems="center"
						spacing={2}
					>
						<Grid item>
							<WebIcon sx={{ fontSize: 80 }} />{" "}
							{/* Double the size */}
						</Grid>
						<Grid item>
							<Box display="flex" alignItems="center" gap={1}>
								<Link
									href="https://www.npmjs.com/package/@expertstack-studios/sc-web-sdk"
									target="_blank"
									rel="noopener noreferrer"
									color="primary"
									underline="always"
								>
									Web SDK Documentation
								</Link>
								{/* <span>|</span>
                <Link
                  href="https://www.youtube.com/watch?v=ts0K1YDxQMY"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary"
                  underline="always"
                >
                  Web SDK Integration Video
                </Link> */}
							</Box>
						</Grid>
						<Grid item sx={{ width: "100%" }}>
							{/* Full width */}
							<SCLabel color="#003060">Web SDK Secret</SCLabel>
							<PasswordRevealInput
								value={"Your Web Secret Key"}
								onChange={(value: string) => {
									console.log(value);
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			)}
			{/* OK Button */}

			{(loadingDeviceData || loadingCCData) && (
				<LoadingPlaceHolder message="Loading..."></LoadingPlaceHolder>
			)}
			<br />
			<PrimaryButton
				variant="text"
				color="info"
				label="Ok"
				cssClassName="primary-button"
				disabled={false}
				onClick={() => {
					setLoadingCCData(false);
					setLoadingDeviceData(false);
				}}
			/>
		</>
	);
};

export default ApplicationCredentials;
