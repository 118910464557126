import { ApiService } from "src/network/apiService";
import { BusinessNumberModel } from "src/types/models/apiModels";

export class BusinessNumbersConfigureViewModel {
  private apiService: ApiService;

  constructor(accessToken: string) {
    this.apiService = new ApiService({ accessToken: accessToken });
  }

  // async fetchBusiness(businessId: string): Promise<BusinessListModel[]> {
  //   const response = await this.apiService.getAsync<any>(
  //     `/onboard/business/${businessId}`
  //   );

  //   if (response) {
  //     console.log(`/onboard/business/${businessId}/number/list`, response);
  //     if (response.scResponse && response.scResponse.code === 2000) {
  //       return response.scResponse.data;
  //     }

  //     return [];
  //   }

  //   return [];
  // }
  async fetchList(businessId: string): Promise<BusinessNumberModel[]> {
    const response = await this.apiService.getAsync<any>(
      `/onboard/business/${businessId}/number/list`
    );

    if (response) {
      console.log(`/onboard/business/${businessId}/number/list`, response);
      if (response.scResponse && response.scResponse.code === 2000) {
        if (response.scResponse.data) {
          const returnData: BusinessNumberModel[] = response.scResponse.data;
          return returnData.map((item) => ({
            ...item,
            imageFile: item.imageFile, // If imageFile is null, set it to undefined
          })); // returnData.filter((p) => p.status === "Approved");
        }
      }

      return [];
    }

    return [];
  }
}
