import { ApiService } from "src/network/apiService";
import {
  IAddNewBusinessNumberRequest,
  IAddNewBusinessNumberResponse,
  IBusinessNumber,
} from "src/network/models/AddNewBusinessNumberRequest";
import { ApiResponse } from "src/network/models/ApiResponse";
import { NewNumberModel, ServiceStatus } from "src/types/models/apiModels";

export class NewBusinessNumberViewModel {
  private apiService: ApiService;

  constructor(accessToken: string) {
    this.apiService = new ApiService({ accessToken: accessToken });
  }

  private convertNewNumberToBusinessNumber(
    numbers: NewNumberModel[]
  ): IBusinessNumber[] {
    return numbers.map((number) => ({
      businessNumber: number.number,
      ownershipDocuments: number.ownershipDocuments,
    }));
  }

  // Main method to submit business numbers
  async submitBusinessNumbers(
    businessId: string,
    numbers: NewNumberModel[]
  ): Promise<{ result?: IAddNewBusinessNumberResponse; error?: string }> {
    const businessNumbers = this.convertNewNumberToBusinessNumber(numbers);

    const request: IAddNewBusinessNumberRequest = {
      businessId: businessId,
      businessNumbers: businessNumbers,
    };

    console.debug(`result = ${JSON.stringify(request)}`);

    const result = await this.apiService.postAsync<ApiResponse<IAddNewBusinessNumberResponse>, IAddNewBusinessNumberRequest>("/onboard/business/numbers/submit", request);

    console.debug(`result 1 = ${JSON.stringify(result)}`);

    // Handle API response(You can uncomment and modify the response check as needed)
    if (result.scResponse.code !== 2000) {
      return {
        result: undefined,
        error: result.scResponse.message,
      };
    }

    return {
      result: result.scResponse.data,
    };
  }

  async getNumbers(businessId: string): Promise<NewNumberModel[]> {
    const url = `/onboard/business/${businessId}/number/list`;
    // console.log(url);

    const result = await this.apiService.getAsync<ApiResponse<any[]>>(url);

    // console.log(JSON.stringify(result));

    if (result.scResponse.code === 2000) {
      const data = result.scResponse.data;

      const mappedData: NewNumberModel[] = data.map((number) => ({
        number: number.businessNumber.startsWith("+")
          ? number.businessNumber
          : `+${number.businessNumber}`,

        ownershipDocuments: number.ownershipDocuments.map((doc: any) => ({
          fileName: doc.fileName || "Unknown file",
          version: doc.version || "1.0",
        })),
        status: number.status as ServiceStatus, // Cast status if needed
      }));

      // console.log(`Returning data -> ${JSON.stringify(mappedData)}`);
      return mappedData;
    }
    return [];
  }
}
