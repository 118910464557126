import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signIn } from "src/components/organisms/auth";
import { useAuth } from "./auth-context";
import { TokenHelper } from "./token-helper";
import ButtonPro, {
	ButtonVariant,
} from "src/components/atoms/primary-button/primary-button";
import InputBox from "src/components/atoms/v2/inputBox/inputBox";
import Logo from "src/components/molecules/logo/Logo";
import LeftSection from "./left-section";
import { validateEmail } from "src/helpers/email-address-helper";
import LoadingModal from "src/components/molecules/loading/LoadingModal";

const Login: React.FC = () => {
	const [businessEmail, setBusinessEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const { login, logout } = useAuth();
	const navigate = useNavigate();
	const tokenHelper = useRef<TokenHelper | null>(null);

	useEffect(() => {
		tokenHelper.current = new TokenHelper();
		logout(); // this is to make sure if someone clicks on browser back button they are logged out.
	}, []);

	const handleForgotPasswordClick = (event: React.FormEvent) => {
		event.preventDefault(); // Prevent default link behavior

		if (validateEmail(businessEmail)) {
			navigate("/forgot-password", {
				state: { businessEmail },
			});
		} else {
			navigate(`/forgot-password`);
		}
	};

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		setError("");

		if (!businessEmail || !password) {
			setError("Missing credentials");
			return;
		}

		setLoading(true);

		signIn(businessEmail, password, async (err, result) => {
			if (err) {
				setLoading(false);
				console.log(`Login error ${JSON.stringify(err)}`);
				if (err.code === "UserNotConfirmedException") {
					navigate("/verify", {
						state: { businessEmail, password },
					});
				}
				setError(err.message || JSON.stringify(err));
			} else {
				// console.log(`Login result ${JSON.stringify(result)}`);
				const tokenProps = await tokenHelper.current?.decodeToken(
					result.accessToken.jwtToken
				);

				login({
					id: result.idToken.payload.sub,
					givenName: result.idToken.payload.given_name,
					familyName: result.idToken.payload.family_name,
					username: businessEmail,
					phoneNumber: result.idToken.payload.phone_number,
					ex_user_id: result.accessToken.payload.ex_user_id,
					accessToken: result.accessToken.jwtToken,
					num: result.accessToken.payload.num,
					accountId: tokenProps?.account_id ?? "",
					email: tokenProps?.email ?? "",
					role: tokenProps?.role,
					exp: result.accessToken.payload.exp,
				}); // Set the authentication state to true with user data
				setLoading(false);
				navigate("/"); // Navigate to the protected page
			}
		});
	};

	return (
		<>
			<div className="font-Poppins bg-transparent min-h-screen h-screen w-screen md:flex md:items-center md:justify-center">
				<div className="flex items-center justify-start h-fit md:h-[65vh]">
					{/* left */}
					<LeftSection></LeftSection>
					{/* right */}
					<div className="md:bg-slate-50 md:max-w-[550px] pt-16 px-12 h-full w-screen md:shadow-[0_30px_50px_rgba(0,0,0,.5)] md:rounded-r-2xl">
						<div className="md:hidden py-4">
							<Logo></Logo>
						</div>
						<span className="bg-gradient-to-bl from-sky-600 to-sky-800 mt-4 text-4xl md:text-6xl font-bold lg:text-4xl bg-clip-text text-transparent uppercase p-4 drop-shadow-2xl">
							Login
						</span>
						<form
							className="flex flex-col justify-center items-center w-full"
							onSubmit={handleSubmit}
						>
							<div className="flex flex-col items-center justify-between gap-6 mt-12 w-full">
								<InputBox
									value={businessEmail}
									onChange={(value) => {
										setBusinessEmail(value);
									}}
									label="User Name"
									type={"text"}
									required
								></InputBox>

								<InputBox
									value={password}
									onChange={(value) => {
										setPassword(value);
									}}
									label="Password"
									type={"password"}
									required
								></InputBox>

								<div className="flex justify-end text-right w-full">
									<a
										href="#"
										onClick={handleForgotPasswordClick}
										className="text-blue-600 dark:text-blue-500 font-semibold hover:underline"
									>
										Forgot password?
									</a>
								</div>
								<span className="text-red-500 font-semibold">
									{error}
								</span>
								<div className="flex flex-col items-center justify-center gap-4 md:gap-2 pt-4 w-full">
									<ButtonPro
										type="submit"
										label={"Login"}
									></ButtonPro>

									<div className="flex flex-row flex-1">
										<div className="border-t-2 border-gray-300 w-1/2"></div>
										<span className="mx-4 font-semibold text-gray-900">
											Or
										</span>
										<div className="border-t-2 border-gray-300 w-1/4"></div>
									</div>

									<ButtonPro
										variant={ButtonVariant.Secondary}
										label={"Create new Account"}
										onClick={() => navigate("/signup")}
									></ButtonPro>
									<LoadingModal
										open={loading}
										message="Please wait..."
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
